// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rotate-bar{--circle-stroke-width:2px;opacity:.4;transition:opacity .3s linear}.rotate-bar:hover{opacity:1}.rotate-bar__svg--circle{fill:none;filter:drop-shadow(0 .0625rem .125rem rgba(0,0,0,.32));stroke-width:var(--circle-stroke-width);stroke:#fff}.rotate-bar__svg--circle2{fill:#fff;cursor:pointer;filter:drop-shadow(rgba(0,0,0,.32) 0 1px 2px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
