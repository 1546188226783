<template>
  <client-only v-if="refresh">
    <div
      :class="{
      'carousel-wrap': true,
      'overflow-hidden': !editing,
      'carousel_wrap_desk': !isMobile,
      'carousel_wrap_mobile': isMobile
    }"
    >
      <div id="swiper" ref="swiper" class="swiper">
        <div class="slide-block swiper-wrapper" :class="model.effect">
          <div
            v-for="(item) in model.children"
            :key="item.id"
            class="h-auto swiper-slide"
            :style="{ height: calculateHeight + 'px' }"
            @click="handleImgClick(item)"
          >
            <bg-style
              class="h-full"
              :bg="isMobile ? item.mobileBackground : {
                backgroundImage: item.backgroundImage,
                backgroundSize: item.backgroundSize,
                backgroundRepeat: 'no-repeat',
                backgroundColor: item.backgroundColor,
                backgroundColorShow: item.backgroundColorShow,
                backgroundImageShow: item.backgroundImageShow,
                backgroundOpacity: item.backgroundOpacity
              } "
            >
              <div class="h-full text_wrap">
                <im-row class="relative z-20 w-full h-full slide-item">
                  <im-col class="flex flex-col justify-center h-full" :lg="18" :xs="{ span: 24 }">
                    <rich-text
                      v-if="model.hasTitle"
                      v-model="item.title"
                      :editing="editing"
                      :disabled="!editing"
                      theme="snow"
                      placement="bottom"
                      class="font-bold item_main-title swiper-no-swiping site-media"
                    />
                    <rich-text
                      v-if="model.hasDescribe"
                      v-model="item.subTitle"
                      :editing="editing"
                      :disabled="!editing"
                      theme="snow"
                      placement="top"
                      class="item_sub-title site-media swiper-no-swiping"
                    />
                    <div class="flex flex-wrap items-center item-left__main-button">
                      <rich-button
                        v-for="(btn, btnIndex) in item.buttons"
                        :key="btnIndex"
                        v-model="btn.name"
                        :is-edit="false"
                        class="carousel-collation swiper-no-swiping site-media link_btns"
                        @click.native="handleButtonClick(btn)"
                      />
                    </div>
                  </im-col>
                </im-row>
              </div>
            </bg-style>
          </div>
        </div>
        <div v-if="model.children.length > 1" class="swiper-pagination" />
        <ul
          v-if="model.hasController && model.children.length > 1"
          :class="['swiper-prev', 'left-[10px]', { 'xl:left-[40px]': $store.getters.isDesktop }]"
        >
          <div class="button-prev" @click.stop="handleChange('prev')">
            <im-icon class="cursor-pointer" icon="icon-lunbozuo" />
          </div>
        </ul>
        <ul
          v-if="model.hasController && model.children.length > 1"
          :class="['swiper-next', 'right-[10px]', {
            'xl:right-[40px]': $store.getters.isDesktop
          }]"
        >
          <div class="button-next" @click.stop="handleChange('next')">
            <im-icon icon="icon-lunboyou" />
          </div>
        </ul>
      </div>
    </div>
  </client-only>
</template>

<script>
import { Swiper, Navigation, Pagination, Autoplay } from 'swiper'
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import {DeviceEnum, DeviceWidthEnum} from "~/enums/deviceEnum";
import RichText from "~/components/richText";
import { CarouselWidgetModel } from '~/site/model'
import imRow from '~/site/components/row'
import imCol from '~/site/components/col'
import RichButton from "~/components/richButton";
import { scrollTo } from '~/utils/dom'
import 'swiper/swiper-bundle.min.css'
import {navTo} from "~/utils";

export default {
  components: {
    RichButton,
    RichText,
    imRow,
    imCol
  },
  props: {
    model: {
      type: Object,
      default() {
        return CarouselWidgetModel
      }
    },
    device: {
      type: String,
      default: 'desktop'
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CarouselWidgetModel,
      refresh: true,
      active: 0,
      swiper: {}
    }
  },
  computed: {
    calculateHeight() {
      const dividend = this.isMobile
        ? 2
        : 3
      const deviceWidth = this.isMobile ? DeviceWidthEnum.MOBILE : this.$store.state.editor.pcDeviceWidth
      return Math.ceil(deviceWidth / dividend)
    },

    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },

    /**
     * 是否需要平铺渲染
     *
     * 1. 移动端只使用平铺式渲染轮播图
     * 2. pc端根据用户配置来决定使用（平铺式|卡片式）渲染轮播图
     */
    isTileSwiper() {
      return this.isMobile || this.model.effect === 'tile'
    }
  },

  watch: {
    device() {
      this.handleRefresh()
    },

    deviceWidth() {
      this.deviceWidthChange()
    },

    'model.effect'() {
      this.handleRefresh()
      this.deviceWidthChange()
    }
  },

  created() {
    this.init()
  },

  mounted() {
    this.getDefaultDeviceWidth()
    this.deviceWidthChange()
    this.initSwiper()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.throttleInitSwiper)
  },

  methods: {
    getDefaultDeviceWidth() {
      if (this.$store.state.editor.pcDeviceWidth) return
      this.$nextTick(() => {
        const width = document.getElementById('site-body')?.clientWidth
        this.$store.commit('editor/SET_PC_DEVICE_WIDTH', width)
      })
    },
    init() {
      this.model.__refresh = this.handleRefresh
      this.model.__onMenuDragEnd = this.handleRefresh
      this.model.__initSwiper = this.initSwiper
      this.model.__addMenuSelectedEvent(this.handleSlide)
      window.addEventListener('resize', this.throttleInitSwiper)
    },
    /**
     * 卡片式渲染轮播图（pc端的一个模式）
     */
    cardSwiper() {
      Swiper.use([Navigation, Pagination, Autoplay])
      const that = this
      const { children } = this.model
      this.active = 0
      this.swiper = null
      const autoplay = this.editing ? false : this.model.autoplay ? { disableOnInteraction: false, delay: 5000 } : false
      const loop = !this.editing && this.model.children.length > 2
      const timer = setTimeout(() => {
        that.swiper = new Swiper(this.$refs.swiper, {
          preventClicks: false,
          slideToClickedSlide: true,
          watchSlidesProgress: true,
          centeredSlides: true,
          autoplay,
          loop,
          updateOnWindowResize: true,
          resistanceRatio: 0,
          loopedSlides: children.length,
          direction: 'horizontal',
          modules: [Navigation, Pagination, Autoplay],
          slidesPerView: 'auto',
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev'
          },
          on: {
            init() {
            },
            progress() {
              for (let i = 0; i < this.slides.length; i++) {
                const slide = this.slides.eq(i);
                const slideProgress = this.slides[i].progress
                let modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
                }
                const translate = slideProgress * modify * 130 + 'px'
                const scale = 1 - Math.abs(slideProgress) / 5
                const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
                slide.transform('translateX(' + translate + ') scale(' + scale + ')')
                slide.css('zIndex', zIndex)
                slide.css('opacity', 1)
                if (Math.abs(slideProgress) > 3) {
                  slide.css('opacity', 0)
                }
              }
            },
            setTransition(transition) {
              for (let i = 0; i < this.slides.length; i++) {
                const slide = this.slides.eq(i)
                slide.transition(Number(transition))
              }
            },
            slideChangeTransitionEnd() {
              that.onSlideChange(this.realIndex)
            }
          }
        })
        clearTimeout(timer)
      }, 0)
    },

    /**
     * 平铺式渲染轮播图（移动端渲染模式及pc端的一个模式）
     */
    tileSwiper() {
      Swiper.use([Navigation, Pagination, Autoplay])
      const that = this
      this.active = 0
      this.swiper = null
      const autoplay = this.editing ? false : this.model.autoplay ? { disableOnInteraction: false, delay: 5000 } : false
      const loop = !this.editing
      const timer = setTimeout(() => {
        that.swiper = new Swiper(this.$refs.swiper, {
          preventClicks: false,
          loop,
          updateOnWindowResize: false,
          height: this.calculateHeight,
          resistanceRatio: 0,
          direction: 'horizontal',
          modules: [Navigation, Pagination, Autoplay],
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          autoplay,
          navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev'
          },
          on: {
            init() { },
            slideChangeTransitionEnd() {
              that.onSlideChange(this.activeIndex)
            }
          }
        })
        clearTimeout(timer)
      }, 0)
    },

    /**
     * 初始化轮播图
     */
    initSwiper() {
      if (this.isMobile) {
        this.model.effect = 'tile'
      }
      if (this.isTileSwiper) {
        this.tileSwiper()
      } else {
        this.cardSwiper()
      }
    },
    handleRefresh() {
      this.refresh = false
      const timeId = setTimeout(() => {
        this.refresh = true
        this.initSwiper()
        this.deviceWidthChange()
        clearTimeout(timeId)
      }, 500)
    },
    handleButtonClick(item) {
      if (this.editing) return
      navTo(this, item)
      // item.url && this.SitePage.to(item.url)
      // item.good && this.scrollGood(item.good)
      // item.url && window.open(item.url, '_blank')
    },
    handleImgClick(item) {
      if (this.editing) return
      navTo(this, item)
      // item.img_url && this.SitePage.to(item.img_url)
      // item.img_good && this.scrollGood(item.img_good)
    },

    /**
     * 处理商品导航
     *
     * @param {*} good
     */
    scrollGood(good) {
      scrollTo(`${good}`, 'site-body', this.isMobile ? 60 : 90)
    },

    handleSlide({ childIndex }) {
      this.active = childIndex
      this.model.__activeIndex = childIndex
      this.$nextTick(() => {
        this.swiper?.slideTo(childIndex)
      })
    },
    onSlideChange(index) {
      this.active = index
    },
    handleChange(dir) {
      const slideFun = dir === 'next' ? 'slideNext' : 'slidePrev'
      this.swiper[slideFun]()
      this.SiteMenu.close()
    },
    handleShowBackground() {
      this.model.__onMenuSelected({ childIndex: this.active })
    },

    /**
     * 监听设备宽度，实时设置轮播图高度，实现比例放缩
     */
     deviceWidthChange: debounce(function () {
      if (this.isTileSwiper) {
        const ele = document.getElementById('swiper')
        if (ele && (this.calculateHeight > 0)) {
          ele.style.height = `${this.calculateHeight}px`
        }
      }
    }, 0),

    /**
     * 处理页面 resize时，轮播图没有变化
     */
    throttleInitSwiper: throttle(function () {
      this.$nextTick(this.handleRefresh)
    }, 500)
  }
}
</script>

<style lang="less" scoped>
.carousel_wrap_desk {
  font-family: PingFang SC;
  .text_wrap {
    max-width: 1280px;
    margin: 0 auto;
  }

  .item_main-title {
    font-size: 60px;
    line-height: 96px;
    margin-bottom: 16px;
  }

  .item_sub-title {
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 36px;
  }

  .link_btns {
    font-size: 24px;
    line-height: 36px;
    min-width: 180px;
    padding: 15px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.carousel_wrap_mobile {
  font-family: PingFang SC;
  .text_wrap {
    width: 100%;
    margin: 0;
    padding: 0 45px;
  }

  .item_main-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .item_sub-title {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .link_btns {
    min-width: 80px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.swiper-wrapper.card {
  .slide-item {
    padding: 5rem;
  }
}
</style>

<style lang="less">
.@{mobile-cls} {
  .carousel-wrap {
    .slide-item-right__main-image {
      margin-top: 24px;
    }
    .slide-block.card {
      .swiper-slide {
        min-height: unset;
      }
    }
  }
}

.carousel-wrap {
  --swiper-pagination-color: white;
  --swiper-navigation-color: white;
  --swiper-navigation-size: 30px;
  // overflow: hidden;
  position: relative;

  .slide-block {
    &.card {
      .swiper-slide {
        width: 1000px;
        min-height: 320px;
        filter: grayscale(100%);
      }
      .swiper-slide-active {
        filter: none;
      }
    }
    .slide-item {
      background-size: 100%;
      // padding: 5rem;

      .item-left__main-button {
        display: flex;
        align-items: center;

        .rich-button {
          word-break: break-word;
          word-wrap: break-word;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.carousel-collation {
            position: relative;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            overflow: hidden;
            border-radius: var(--button-border-radius-px);
            background-color: var(--button-background-color);
            background-image: var(--button-background-image);
            opacity: var(--button-background-opacity);
          }
        }
      }

      &-right__main-image {
        width: 100%;
        height: 248px;
        position: relative;
        border: 1px dashed @error-color;
        background-size: 100%;
        flex-shrink: 0;

        &.is-work {
          border: none;
        }

        &__background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            cursor: pointer;

            .cover-wrap__btn {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .controller-mixin() {
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease 0.2s;
    .im-icon {
      color: @primary-color;
    }
    .swiper-button-prev {
      left: 20px;
    }
    .swiper-button-next {
      right: 20px;
    }
  }
  .circle-btn() {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    text-align: center;
    line-height: 32px;
    background-color: @fill-color-4;
    position: relative;
    z-index: 10;
    &:hover {
      background-color: @text-7;
    }
  }

  .swiper-prev {
    .controller-mixin();
    left: 0;

    .button-prev {
      .circle-btn();
    }
  }

  .swiper-next {
    .controller-mixin();
    right: 0;

    .button-next {
      .circle-btn();
    }
  }

  .swiper-pagination {
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    &__content {
      .nav-item {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 100%;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.6);
        margin: 0 0.5rem;

        &.active {
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>
