// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".month-card-result.mobile-edit .el-dialog{left:10px}.month-card-result .rich-text.focus-editor .ql-container.ql-snow{background-color:transparent}.month-card-result .title{color:var(--text-color)}.month-card-result .ok-button{color:var(--button-color)}.month-card-result .list{gap:20px}.month-card-result .list .item{align-items:center;display:flex;flex-shrink:0;height:60px;justify-content:center;width:60px}.month-card-result .list .item .number{bottom:0;color:var(--prize-number-color);font-size:12px;left:0;line-height:16px;position:absolute!important;text-align:center;width:100%}.month-card-result .el-dialog__header{font-weight:700;padding:0;text-align:center}.month-card-result .el-dialog__header .el-dialog__headerbtn{right:10px;top:10px}.month-card-result .el-dialog__header .el-dialog__title{font-size:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
