<template>
  <div v-loading="loading" class="assets-library">
    <im-tip v-if="tip" class="mb-3" type="warning" :text="tip" />
    <el-scrollbar class="assets-library__scroll" :style="{ height: scrollHeight + 'px' }">
      <div class="assets-library__list flex">
        <upload
          action=""
          :show-file-list="false"
          :http-request="debounceUpload"
          accept=".ico,.png,.gif,.jpg,.jpeg"
          multiple
          :before-upload="beforeAvatarUpload"
        >
          <div class="assets-library__upload">
            <icon-upload />
            <div class="text-[12px] mt-1">上传</div>
          </div>
        </upload>
        <div v-if="inWidget" class="assets-library__delete" @click.stop="handleClear">
          <i class="el-icon-close text-20" />
        </div>
        <assets-item v-for="(item, index) in $store.state.assets.assetsList" :key="item.resource_id" :is-active="value === item.url" :loading="item.loading" :url="item.url" @remove="onRemove(item, index)" @select="onSelect" />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import {Message, Upload} from 'element-ui'
import AssetsItem from './item.vue'
import iconUpload from '@/assets/svg/icon/iconUpload.svg'
import {rename} from "~/utils";
export default {
  name: 'AssetsLibrary',
  components: {
    iconUpload,
    Upload,
    AssetsItem
  },
  props: {
    tip: {
      type: String,
      default: ''
    },
    scrollHeight: {
      type: [Number, String],
      default: 300
    },
    inWidget: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Boolean],
      default: ''
    },
    limit: {
      type: Number,
      default: 2 // 2Mb
    }
  },
  data() {
    return {
      loading: true,
      debounceUpload: Function,
      taskFiles: []
    }
  },
  computed: {
    ...mapState({
      projectId: state => state.project.projectId,
      merchantId: state => state.project.merchantId,
      domain: state => state.project.domain,
      siteId: state => state.project?.info?.id
    })
  },
  created() {
    if (process.client) {
      this.debounceUpload = debounce(this.uploadRequest, 500)
    }
  },
  methods: {
    async uploadRequest() {
      if (!this.taskFiles.length) return
      const formData = new FormData()
      const user = this.$cookies.get('userInfo')
      formData.set('operator', user?.id || '')
      this.taskFiles.forEach(file => {
        const assetsItem = {
          uid: file.name,
          url: null,
          loading: true
        }
        this.$store.commit('assets/ADD_ASSETS_ITEM', assetsItem)
        formData.append('files', file)
      })
      formData.append('merchant_id', this.merchantId)
      formData.append('project_id', this.projectId)
      formData.append('directory', this.siteId)
      const files = await this.$api.assets.batchAddAssets(formData)
      if (files?.data) {
        this.taskFiles = []
        files.data.forEach(item => {
          const name = item.url.substring(item.url.lastIndexOf('/') + 1, item.url.length)
          const updateItem = {
            uid: name.substring(name.indexOf('_') + 1, name.length),
            url: item.url,
            resource_id: item.resource_id,
            loading: false
          }
          this.$store.commit('assets/UPDATE_ASSETS_ITEM', updateItem)
        })
      }
    },
    init () {
      if (!this.$store.state.assets.assetsList.length) {
        this.getAssets()
      } else {
        this.loading = false
      }
    },
    handleClear() {
      this.$emit('change', '')
    },
    beforeAvatarUpload(file) {
      const newFile = new File([file], rename(file.name, [' ', ')', '(']), { type: file.type })
      const isLtM = file.size / 1024 / 1024 < this.limit;
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isLtM) {
        Message.error(`上传头像图片大小不能超过${this.limit}MB!`)
      } else if (!isJpgOrPng) {
        Message.error(`${file.name}文件格式错误!`)
      } else {
        this.taskFiles.push(newFile)
      }
      return isLtM;
    },
    async getAssets() {
      const params = {
        directory: this.siteId,
        merchant_id: this.merchantId,
        project_id: this.projectId
      }
      const res = await this.$api.assets.getAssets(params)
      const data = res.list || []
      const list = data.map(item => {
        const newItem = {...item}
        newItem.url = encodeURI(newItem.url)
        return newItem
      }) || []
      this.loading = false
      this.$store.commit('assets/SET_ASSETS_LIST', list)
    },
    onSelect(url) {
      this.$emit('change', url)
    },
    async onRemove({ resource_id }, index) {
      if (!resource_id) {
        return
      }
      const res = await this.$api.assets.deleteAsset({ resource_id })
      if (!res) return
      this.$store.commit('assets/DELETE_ASSETS_ITEM', index)
    }
  }
}
</script>

<style lang="less">
.assets-library {
  &__list {
    gap: 8px;
    flex-wrap: wrap;
  }
  &__scroll {
    //
  }
  &__upload {
    width: 60px;
    height: 60px;
    border-radius: @border-radius-base;
    border: 1px dashed #fff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:hover {
      border-color: @primary-1;
      color: @primary-1;
    }
  }
  &__delete {
    width: 60px;
    height: 60px;
    background-color: @fill-color-3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: @border-radius-base;
    cursor: pointer;
    flex-shrink: 0;
  }
}
</style>
