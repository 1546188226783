<template>
<div v-if="model.enabled && visible" class="notice-widget">
  <bg-style ref="$container" class="notice-widget__content" :bg="model.background">
    <span ref="$content" class="content relative" :style="{...getTextColor(model.textColor), ...contentStyle}">{{ getLangIdI18n({ lang_id: model.content }, $store.state.locale.langIdData) }}</span>
    <div v-if="!editing" class="notice-widget__close" @click="handleClose">
      <i class="el-icon-close" />
    </div>
  </bg-style>
</div>
</template>

<script>
import debounce from 'lodash.debounce';
import {getLangIdI18n, getTextColor} from "~/utils";

export default {
  props: {
    device: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: true,
      animateName: null,
      animationDuration: null,
      debounceChange: null
    }
  },
  computed: {
    contentStyle() {
      return {
        '--animate-name': this.animateName,
        '--animate-duration': this.animationDuration
      }
    }
  },
  watch: {
    'model.content'() {
      this.debounceChange()
    },
    'model.enabled'() {
      this.$emit('close')
    }
  },
  mounted() {
    this.debounceChange = debounce(this.setAnimateStatus, 500)
    this.debounceChange()
  },
  methods: {
    getLangIdI18n,
    getTextColor,
    setAnimateStatus() {
      if (!this.model.enabled || !this.visible) return
      const contentWidth = this.$refs.$content?.offsetWidth || 0
      const containerWidth = this.$refs.$container?.$el?.offsetWidth || 0
      if (contentWidth > containerWidth) {
        this.animateName = 'scroll-animate'
        const time = contentWidth / 50
        this.animationDuration = time + 's'
      } else {
        this.animationDuration = null
        this.animateName = null
      }
    },
    handleClose() {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="less">
.notice-widget {
  //height: 48px;
  //transition: all 0.2s ease-in-out;
  //&.close {
  //height: 0;
  //}
  &__content {
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    overflow: hidden;
    .content {
      display: inline-block;
      white-space: nowrap;
      animation: var(--animate-name) linear var(--animate-duration) infinite;
    }

    &:hover {
      .notice-widget__close {
        display: flex;
      }
    }
    @keyframes scroll-animate {
      0% {
        transform: translateX(100vw); /* 100% 改为 100vw */
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    border-radius: 100%;
    right: 20px;
    top: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: none;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
