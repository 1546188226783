<template>
  <div v-if="model.enabled" class="pwa-widget">
    <div v-if="editing || deferredPrompt" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" class="pwa-widget__header">
      <div class="content">
        <rich-text v-model="model.title" :disabled="!editing" :editing="editing" class="describe" placement="bottom" theme="snow"/>
        <div class="pwa-button" @click="handleDownload">{{ model.buttonName }}</div>
      </div>
    </div>
    <div v-if="drawerVisible" :class="{ 'is-mobile': device === DeviceEnum.MOBILE, 'is-edit': editing }" class="pwa-widget__drawer">
      <i class="el-icon-close text-black" @click="handleClose"/>
      <div class="title text-[20px] text-center text-black">{{ $t('siteBuild.pwaWidget.title') }}</div>
      <div class="subtitle text-center text-black mt-5">{{ $t('siteBuild.pwaWidget.tip1') }}</div>
      <div class="text-center text-black mt-2">{{ $t('siteBuild.pwaWidget.tip2') }}</div>
      <div class="content mt-8">
        <img alt="pwa" src="@/assets/img/pwa.png">
      </div>
    </div>
  </div>
</template>

<script>
import RichText from "~/components/richText/index.vue"
import {DeviceEnum} from "~/enums/deviceEnum"

export default {
  components: {RichText},
  props: {
    device: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      deferredPrompt: null
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    drawerVisible() {
      let result = false
      const isMobile = this.device === DeviceEnum.MOBILE
      if ((this.editing && isMobile) && this.$store.state.site.pwaDrawerVisible) {
        result = true
      } else if (!this.editing && this.$store.state.site.pwaDrawerVisible) {
        result = true
      }
      return result
    }
  },
  mounted() {
    this.initPwa()
  },
  beforeDestroy() {
    //
  },
  methods: {
    initPwa() {
      const that = this
      window.addEventListener("beforeinstallprompt", function (t) {
        t.preventDefault()
        that.$store.commit('site/SET_PWA_PROMPT', t)
        that.deferredPrompt = t
      })
      window.addEventListener("appinstalled", function (t) {
        that.$store.commit('site/SET_PWA_PROMPT', null)
        that.deferredPrompt = null
      })
    },
    handleClose() {
      this.$store.commit('site/SET_PWA_DRAWER_VISIBLE', false)
    },
    handleDownload() {
      const isMobile = this.device === DeviceEnum.MOBILE
      if (isMobile) {
        this.$store.commit('site/SET_PWA_DRAWER_VISIBLE', true)
      } else if (!isMobile && !this.editing) {
        this.deferredPrompt && this.deferredPrompt.prompt()
      }
    }
  }
}
</script>

<style lang="less">
.pwa-widget {
  &__header {
    width: 100%;
    height: 48px;
    z-index: 200;
    background-color: rgba(0, 0, 0, 1);

    .describe {
    }

    .content {
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 100%;
      padding: 0 100px;

      .pwa-button {
        background-color: var(--button-background-color);
        color: currentColor;
        cursor: pointer;
        border-radius: 2px;
        padding: 4px;
      }
    }

    &.is-mobile {
      height: 80px;

      .content {
        width: 100%;
        padding: 0 20px;
        flex-direction: column;

        .describe {
          width: 100%;

          .ql-editor > p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }
        }

        .pwa-button {
          padding: 2px 5px;
        }
      }
    }
  }

  &__drawer {
    position: fixed;
    background-color: white;
    z-index: 200;
    padding: 40px 40px 0 40px;
    border-top: 1px solid #dedede;

    &.is-mobile {
      left: 0;
      bottom: 0;
      width: 100%;
      transform: none;
    }

    &.is-edit.is-mobile {
      left: calc(50% + 12px);
      transform: translate(-50%, 0);
      bottom: 0;
      width: @edit-mobile-w;
    }

    .el-icon-close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: black;
      font-size: 18px;
      cursor: pointer;
    }

    .content {
      padding-bottom: 60px;

      .cover-btn {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
