<template>
  <Form
    ref="formRef"
    :model="formData"
    :rules="getFormRules"
    layout="vertical"
    autocomplete="off"
    @keypress.enter="handleLogin"
  >
    <div
      :class="['flex items-center relative', !isMobile ? 'text-14 leading-[22px] mb-[25px]' : 'text-16 leading-[24px] mb-[33px]']"
      :style="{color:mainTextColor }"
    >
      <span :class="!isMobile ? 'mr-2' : 'mr-1'">{{ $t('siteBuild.header.noAccountPleaseClick') }}</span>
      <Button
        class="register-button"
        type="text"
        size="small"
        :style="buttonColor"
        @click="registerMethod"
      >{{ $t('siteBuild.header.registerButton') }}</Button>
    </div>
    <!-- isMobile ? $t('siteBuild.header.email'): '' -->
    <FormItem prop="email" :label="$t('siteBuild.header.email')">
      <Input
        v-model.trim="formData.email"
        :style="mainButtonColor"
        class="mt-2"
        size="large"
        :clearable="true"
        :height="48"
        :placeholder="$t('siteBuild.header.email')"
      />
    </FormItem>
    <!-- isMobile ? $t('siteBuild.header.password'): '' -->
    <FormItem prop="password" :label="$t('siteBuild.header.password')">
      <InputPassword
        v-model.trim="formData.password"
        class="mt-2"
        size="large"
        type="password"
        :style="mainButtonColor"
        :height="48"
        :clearable="true"
        :placeholder="$t('siteBuild.header.password')"
      ></InputPassword>
    </FormItem>
    <FormItem>
      <Button
        class="w-full h-[48px] mb-[24px] mt-[14px]"
        type="primary"
        :style="mainButtonColor"
        :disabled="loginBtnDisabled"
        :normal="true"
        :loading="loginLoading"
        @click="handleLogin"
      >{{ $t('siteBuild.header.login') }}</Button>
      <Button
        class="w-full h-[48px]"
        type="primary"
        :style="newSubBtnMobileColor"
        :normal="true"
        @click="handleBind"
      >{{ $t('siteBuild.header.loginByUid') }}</Button>
      <div
        class="w-full flex items-center justify-center mt-4"
      >
        <Button
          type="text"
          class="forget_btn"
          :style="subButtonColor"
          @click="forgetpassMethod"
        >{{ $t('siteBuild.header.forgetPassword') }}</Button>
      </div>
    </FormItem>
  </Form>
</template>

<script>
import colorMixin from './colorMixins'
import mobileMixin from './mobileMixins'
import { useFormRules, useLoginState, LoginStateEnum, useFormValid } from "./useLogin";
import { Form, FormItem, Input, Button } from '~/site/components/forms';
import EventTrackerMixins from '~/mixins/eventTracker'

export default {
  name: "LoginForm",
  components: {
    Input,
    InputPassword: Input,
    Form,
    FormItem,
    Button,
  },
  mixins: [colorMixin, mobileMixin, EventTrackerMixins],
  props: {
    loginLoading: {
      type: Boolean,
      default: true,
    },
    editing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const { setLoginState, } = useLoginState()
    const { loginFormRules, } = useFormRules(this.formData, this);
    return {
      formData: {
        email: '', // 543803983@qq.com
        password: '', // Qw
      },
      loading: false,
      setLoginState,
      getFormRules: loginFormRules
    }
  },
  computed: {
    loginBtnDisabled() {
      if (this.editing) {
        return false
      }
      return !(this.formData.email && this.formData.password)
    }
  },
  mounted() {
    //
  },
  methods: {
    forgetpassMethod() {
      this.setLoginState(LoginStateEnum.FORGET_PASSWORD);
      // this.$logger.captureMessage('忘记密码点击')
    },
    registerMethod() {
      this.setLoginState(LoginStateEnum.REGISTER);
      // this.$logger.captureMessage('注册按钮点击')
    },
    handleBind() {
      this.$store.commit('user/SET_SITE_BIND_USER', true)
      // this.$logger.captureMessage('点击用户ID登录')
    },
    handleLogin() {
      if (this.editing) {
        return
      }
      const { validForm } = useFormValid(this.$refs.formRef)
      validForm().then((data) => {
        this.$emit('login', { ...this.formData })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.normal-link {
  color: @text-color;
}
.register-button {
  text-decoration: underline;
}
</style>
