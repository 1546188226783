// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notice-widget__content{font-size:18px;line-height:48px;overflow:hidden;text-align:center;width:100%}.notice-widget__content .content{animation:var(--animate-name) linear var(--animate-duration) infinite;display:inline-block;white-space:nowrap}.notice-widget__content:hover .notice-widget__close{display:flex}@keyframes scroll-animate{0%{transform:translateX(100vw)}to{transform:translateX(-100%)}}.notice-widget__close{align-items:center;background-color:hsla(0,0%,100%,.8);border-radius:100%;cursor:pointer;display:none;font-size:14px;font-weight:700;height:24px;justify-content:center;position:absolute;right:20px;top:10px;width:24px}.notice-widget__close:hover{background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
