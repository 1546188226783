<template>
  <div ref="rotate" class="color-model__rotate">
    <div class="preview-rotate" style="background: repeating-conic-gradient(var(--p-color-bg-surface) 0 25%, var(--p-color-bg-surface-secondary) 0 50%) 50%/1rem 1rem">
      <rotate-bar ref="bar" @spin="onRotate" />
    </div>
    <div class="color-model__edg mb-4 flex items-center justify-between">
      <div class="text-textColor-3 cursor-pointer hover:text-white transition-all" @click="handleDelete">
        <i class="el-icon-delete" />
        <span class="text-12">移除渐变</span>
      </div>
      <base-input v-model="deg" :maxlength="3" class="w-[70px]" @change="onChange" />
    </div>
  </div>
</template>

<script>
import RotateBar from "~/components/colorPickers/rotateBar.vue";
import BaseInput from "~/components/base/baseInput.vue";
export default {
  name: "GradientRotate",
  components: {
    BaseInput,
    RotateBar
  },
  props: {
  },
  data() {
    return {
      deg: 0
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    spinning() {
      this.$emit('spin', Number(this.deg))
    },
    setDeg(deg) {
      this.deg = deg
      this.$refs.bar.setEdg(deg)
    },
    onRotate(edg) {
      this.deg = String(edg)
      this.spinning()
    },
    handleDelete() {
      this.$emit('delete')
    },
    onChange(value) {
      const number = Number(value ? String(value).replace(/[^0-9]/ig,"") : '0')
      console.log(number)
      this.deg = String(number >= 360 ? 360 : number)
      this.$refs.bar.setEdg(number)
      this.spinning()
    }
  }
}
</script>

<style lang="less">
.color-model__rotate {
  --p-color-bg-surface: #fff;
  --p-color-bg-surface-secondary: #f7f7f7;
  --p-space-400: 1rem;
  .preview-rotate {
    height: 96px;
    width: 100%;
    position: relative;
    border-radius: 4px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: "";
      background: var(--osw-gradient-background);
      border-radius: 4px;
      box-shadow: inset 0 0 0 0.0625rem #0003;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .color-model__edg {
    .im30-site-builder-input {
      position: relative;
      &:after {
        content: "。";
        position: absolute;
        right: 10px;
        top: -5px;
        color: white;
      }
      .el-input__inner {
        color: white;
      }
    }
  }
}

</style>
