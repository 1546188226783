<template>
  <bg-style :bg="model.background" :class="{ 'is-mobile': $store.getters.isMobile }" :style="getStyle"
            class="month-card">
    <div class="month-card__content">
      <rich-text v-if="model.titleVisible" v-model="model.title" :class="$store.getters.isDesktop && 'xl:text-[40px]'" :disabled="!editing"
                 :editing="editing" :stroke.sync="model.titleStroke"
                 class="text-[20px] w-full relative title" theme="snow"/>
      <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :class="{ 'text-24': $store.getters.isDesktop }"
                 :disabled="!editing" :editing="editing" :stroke.sync="model.subTitleStroke"
                 class="mt-3 text-14 relative" theme="snow"/>
      <el-empty v-if="disabled && !editing" :description="$t('siteBuild.noActivity')" class="relative"/>
      <bg-style v-else :bg="model.coverBackground" class="month-card__instance mt-[40px]">
        <bg-style v-if="$store.getters.isMobile" :bg="model.mobileCover" class="cover"/>
        <bg-style v-else :bg="model.cover" class="cover"/>
        <div class="left-block"/>
        <div class="right-block relative">
          <bg-style :bg="model.cardBackground" class="detail-area">
            <i class="el-icon-warning-outline help-icon cursor-pointer" @click="handleShowRule"/>
            <div class="title relative">{{ $t('siteBuild.monthCardWidget.nowGet') }}</div>
            <div class="list mt-2 flex flex-wrap">
              <bg-style v-for="(item, index) in nowList" :key="index" :bg="model.prizeBackground" class="item">
                <img :src="item.gift_goods_logo" alt="" class="max-w-[80%] max-h-[80%] relative">
                <bg-style :bg="model.prizeNumberBackground" class="number">
                  <span class="relative">{{ item.num }}</span>
                </bg-style>
              </bg-style>
            </div>
            <div class="title relative mt-[26px]">{{ $t('siteBuild.monthCardWidget.dayGet') }}</div>
            <div class="list mt-2 flex flex-wrap">
              <bg-style v-for="(item, index) in dayList" :key="index" :bg="model.prizeBackground" class="item">
                <img :src="item.gift_goods_logo" alt="" class="max-w-[80%] max-h-[80%] relative">
                <bg-style :bg="model.prizeNumberBackground" class="number">
                  <span class="relative">{{ item.num }}</span>
                </bg-style>
              </bg-style>
            </div>
            <div class="handle-box relative">
              <div class="flex items-center timer">
                <template v-if="showCardCountTime">
                  <i class="el-icon-time"/>
                  <span class="ml-2">{{ cardCountTime }}</span>
                </template>
              </div>
              <div :class="{ 'flex-wrap': editing }" class="flex items-center handle gap-[24px]">
                <bg-style v-if="showCountTime" :bg="model.buttonBackground" class="buy-button !flex-row is-loading"
                          @click.native="handlePay">
                  <i class="el-icon-time relative mr-2"/>
                  <div class="relative">{{ countTime }}</div>
                </bg-style>
                <bg-style v-if="isBuyStatus" :bg="model.buttonBackground" :class="{ 'is-loading': loading }"
                          class="buy-button !flex-row" @click.native="handlePay">
                  <i v-if="loading" class="el-icon-loading relative mr-2"/>
                  <div class="text-center">
                    <div class="relative price">{{ priceInfo.priceTxt }}</div>
                    <div v-if="detail.original_value > 0" class="relative origin-price">{{
                        originPriceInfo.priceTxt
                      }}
                    </div>
                  </div>
                </bg-style>
                <bg-style v-if="isRenewalStatus" :bg="model.buttonBackground" :class="{ 'is-loading': loading }"
                          class="buy-button !flex-row" @click.native="handlePay">
                  <i v-if="loading" class="el-icon-loading relative mr-2"/>
                  <div class="relative">{{ $t('siteBuild.consecutive') }}</div>
                </bg-style>
                <bg-style v-if="isGetStatus" :bg="model.getBackground" :class="{ 'is-loading': getLoading }"
                          class="buy-button !flex-row" @click.native="handleGet">
                  <i v-if="getLoading" class="el-icon-loading relative mr-2"/>
                  <div class="relative flex items-center">
                    <span class="get-text">{{ $t('siteBuild.activity.get') }}</span>
                    <span v-if="canGetNum > 1">
                      <i class="el-icon-close"/>{{ canGetNum }}
                    </span>
                  </div>
                </bg-style>
                <bg-style v-if="isReceiveStatus" :bg="model.receiveBackground" class="buy-button !flex-row">
                  <div class="relative receive-text">{{ $t('siteBuild.activity.haveGet') }}</div>
                </bg-style>
              </div>
            </div>
          </bg-style>
        </div>
      </bg-style>
    </div>
    <DeskPayModal
      v-if="deskPay.visible"
      :card-data="detail"
      :desk-pay="deskPay"
      :lang-id-data="$store.state.locale.langIdData"
      :store-lang="storeLang"
      @close="closePay"
    />
    <result-dialog ref="result" :editing="editing" :list="dayList" :model="model"/>
    <rule-dialog ref="rule" :editing="editing" :model="model"/>
  </bg-style>
</template>

<script>
import tinycolor from "tinycolor2";
import {Message, Empty} from "element-ui";
import {mapState} from "vuex";
import moment from "moment/moment";
import cloneDeep from "lodash.clonedeep";
import RuleDialog from './rule.vue'
import ResultDialog from './result.vue'
import RichText from "~/components/richText/index.vue";
import {DeviceEnum} from "~/enums/deviceEnum";
import tools, {checkUser, formatZoneTime, generatePayUrl, getTextColor} from "~/utils";
import {default_site_lang, isBuildWebSite} from "~/config";
import Bus from "~/site/model/bus";
import {isMobile} from "~/utils/types";
import DeskPayModal from "~/components/pay/deskPayModal.vue";

export default {
  name: 'MonthCardWidget',
  components: {
    DeskPayModal,
    RichText,
    RuleDialog,
    ResultDialog,
    [Empty.name]: Empty
  },
  props: {
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      canGet: false,
      countTime: '00:00:00',
      cardCountTime: '00:00:00',
      detail: {
        prices_setting: {}
      },
      nowList: [],
      dayList: [],
      startTime: '',
      timer: '',
      isCardUser: false,
      loading: false,
      disabled: true,
      storeLang: default_site_lang,
      return_url: '',
      getLoading: false,
      cardStopTime: '',
      canGetNum: 0,
      priceInfo: {},
      originPriceInfo: {}
    }
  },
  computed: {
    showCountTime() {
      return this.countTime !== '00:00:00'
    },
    showCardCountTime() {
      return this.cardCountTime !== '00:00:00'
    },
    id() {
      return this.model.cardId
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      awaitGoods: state => state.site.awaitGoods
    }),
    getStyle() {
      const buttonColor = getTextColor(this.model.buttonColor).color
      const originColor = tinycolor(buttonColor).setAlpha(0.7).toRgbString()
      const textColor = getTextColor(this.model.textColor).color
      return {
        color: textColor,
        '--receive-text-color': getTextColor(this.model.receiveColor).color,
        '--text-color': textColor,
        '--text-color-60': tinycolor(textColor).setAlpha(0.6).toRgbString(),
        '--get-text-color': getTextColor(this.model.getColor).color,
        '--prize-number-color': getTextColor(this.model.prizeNumber).color,
        '--button-color': buttonColor,
        '--origin-price-color': originColor,
        '--modal-background': getTextColor(this.model.modalBackground).color
      }
    },
    isGetStatus() {
      return (this.isCardUser && this.canGet) || this.editing
    },
    isReceiveStatus() {
      return (this.isCardUser && !this.canGet) || this.editing
    },
    isRenewalStatus() {
      return this.isCardUser || this.editing
    },
    isBuyStatus() {
      return (!this.isCardUser && !this.showCountTime) || this.editing
    }
  },
  watch: {
    id(n, o) {
      if (n !== o) {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
    Bus.$on('reloadActivity', () => {
      this.init()
    })
    Bus.$on('reloadGood', (type) => {
      const isLogin = type === 'login'
      if (isLogin) {
        this.checkBuy()
      }
    })
  },
  methods: {
    closePay() {
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
      this.getDetail()
    },
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      if (isAim) this.handlePay(this.awaitGoods.data)
    },
    async handleGet() {
      if (this.editing) return
      const params = {
        project_id: this.userInfo.project_id,
        item_id: this.detail.id
      }
      this.getLoading = true
      const [err, res] = await this.$utils.to(this.$api.good.getCardGift(params))
      this.getLoading = false
      if (!err) {
        Message.success(res.data || 'Success')
        this.$refs.result.init()
        await this.$store.dispatch('goods/getCardList', this)
        await this.getDetail()
      } else {
        Message.error(err)
      }
    },
    async handlePay(data) {
      if (this.loading || this.editing) return
      const isSandBox = !isBuildWebSite
      const return_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?from=${this.$route.query?.from}`
      await checkUser(this, () => {
        this.$store.commit('site/SET_AWAIT_GOODS', {modelId: this.model.id, data: cloneDeep(this.detail)})
      })
      const StoreIpInfo = tools.getLangByip(this)
      const {currency} = StoreIpInfo
      const {project_id, merchant_id} = this.userInfo
      const ipInfo = tools.getxfip(localStorage)
      const priceInfo = tools.getPriceInfo(data?.prices_setting || this.detail.prices_setting, StoreIpInfo, this)
      const price = +priceInfo.price;
      const device = isMobile() ? 'mobile' : 'desktop'
      const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
      const params = {
        project_id,
        merchant_id,
        currency,
        amount: price,
        from_preview_mode: isSandBox,
        items: [
          {
            item_id: data?.id || this.detail.id,
            num: 1,
            amount: price,
            price,
            currency,
            virtual_currency: currency,
            virtual_currency_amount: price,
          },
        ],
        settings: {
          view_source: this.$route.query.from,
          device,
          language: lang,
          show_order: false,
          return_url,
        },
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.order.createOrder(params, ipInfo))
      this.loading = false
      this.$store.commit('site/SET_AWAIT_GOODS', null)
      if (!err) {
        generatePayUrl({...res, monthCardData: cloneDeep(this.detail)}, this)
      } else {
        Message.error(err)
      }
    },
    handleShowRule() {
      this.$refs.rule.init()
    },
    clearData() {
      this.canGet = false
      this.canGetNum = 0
      this.isCardUser = false
    },
    async init() {
      this.clearData()
      await this.getDetail()
    },
    getPrice() {
      const StoreIpInfo = tools.getLangByip(this)
      this.priceInfo = tools.getPriceInfo(this.detail.prices_setting, StoreIpInfo, this)
      this.originPriceInfo = tools.getPriceInfo(this.detail.prices_setting, StoreIpInfo, this, 'originalPrice')
    },
    cartCountdown() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.cardStopTime * 1000).value
      const leftTime = end - now;
      let d = 0;
      let h = 0;
      let m = 0;
      let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.cardCountTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) setTimeout(this.cartCountdown, 1000)
    },
    countdown() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.startTime * 1000).value
      const leftTime = end - now;
      let d = 0;
      let h = 0;
      let m = 0;
      let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.countTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) setTimeout(this.countdown, 1000)
    },
    activityUnusual() {
      if (this.editing) {
        const {nowList, dayList} = cloneDeep(this.model.__mockData)
        this.nowList = nowList
        this.dayList = dayList
        this.getPrice()
      } else {
        this.disabled = true
      }
    },
    async getDetail() {
      await this.$store.dispatch('goods/getCardList', this)
      const current = this.$store.state.goods.cardList.find(item => item.id === this.model.cardId)
      if (!current) {
        this.activityUnusual()
        return
      }
      this.disabled = false
      this.nowList = current.gift_packs_list
      this.dayList = current.gift_list
      this.startTime = current.start_time
      this.cardStopTime = current.card_stop_time
      this.isCardUser = current.is_card_flag
      this.canGet = current.can_card_gift_flag
      this.canGetNum = current.can_card_gift_num
      this.detail = current
      this.getPrice()
      this.countdown()
      this.cartCountdown()
    }
  }
}
</script>

<style lang="less">
.month-card {
  .month-card-rule, .month-card-result {
    .el-dialog {
      background: var(--modal-background);
    }
  }
  &__content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
  }

  &__instance {
    display: flex;
    padding: 20px 24px;

    .cover {
      height: 100%;
      width: 400px;
      position: absolute !important;
      top: 0;
      left: 0;
    }

    .left-block {
      width: 300px;
      flex-shrink: 0;
    }

    .right-block {
      width: 100%;

      .detail-area {
        width: 100%;
        padding: 36px 50px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);

        .help-icon {
          position: absolute;
          top: 35px;
          right: 35px;
          color: var(--text-color-60);
          font-size: 20px;
          z-index: 10;
        }

        .title {
          font-size: 16px;
        }

        .list {
          gap: 18px;

          .item {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .number {
              position: absolute !important;
              width: 100%;
              line-height: 16px;
              bottom: 0;
              left: 0;
              text-align: center;
              font-size: 12px;
              color: var(--prize-number-color);
            }
          }
        }

        .handle-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 32px;

          .timer {
            font-size: 16px;

            .el-icon-time {
              font-size: 20px;
            }
          }

          .buy-button {
            padding: 6px 30px;
            color: var(--button-color);
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 50px;
            cursor: pointer;

            .get-text {
              color: var(--get-text-color);
            }

            .receive-text {
              color: var(--receive-text-color);
            }

            &.is-loading {
              opacity: 0.6;
              pointer-events: none;
            }

            .price {
              font-size: 20px;
              line-height: 1;
            }

            .origin-price {
              font-size: 14px;
              line-height: 1;
              margin-top: 4px;
              color: var(--origin-price-color);
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }

  &.is-mobile {
    .month-card__content {
      padding: 40px 15px;
    }

    .month-card__instance {
      flex-wrap: wrap;
      padding: 12px;

      .cover {
        height: 228px;
        width: 100%;
      }

      .left-block {
        width: 100%;
        height: 140px;
      }

      .detail-area {
        padding: 20px;

        .title {
          font-size: 14px;
        }

        .list {
          width: 100%;
          gap: 8px;

          .item {
            width: 50px;
            height: 50px;
          }
        }

        .help-icon {
          top: 15px;
          right: 15px;
          font-size: 14px;
        }

        .handle-box {
          flex-wrap: wrap;
          margin-top: 20px;

          .timer {
            width: 100%;
            justify-content: center;
            margin-bottom: 20px;
            font-size: 14px;

            .el-icon-time {
              font-size: 16px;
            }
          }

          .handle {
            width: 100%;

            .price {
              font-size: 16px;
            }

            .origin-price {
              font-size: 10px;
            }

            .buy-button {
              width: 100%;
              min-height: 36px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
