x<template>
  <div>
    <menu-item :label="$t('menu.langSelect')">
      <MenuTabButton :value="model.langPosition" :data="model.getPositions()" @change="(val) => {
        model.langPosition = val
      }">
      </MenuTabButton>
    </menu-item>
<!--    <menu-item label="默认语言" style="margin-bottom: 18px;">-->
<!--      <im-select v-model="defaultLang" :options="defaultLangList" size="small" @change="onChangeDefault" />-->
<!--    </menu-item>-->
    <menu-item label="语言">
      <div class="flex flex-col">
        <div v-for="(s, index) in langList" :key="index" :class="{ 'order-1': s.isDefault, 'order-2': !s.isDefault }">
          <menu-switch :value="s.checked" :disabled="s.isDefault" :label="s.label" @change="(val) => switchChange(val, s)" />
        </div>
      </div>
    </menu-item>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { Message } from 'element-ui'
// import cloneDeep from 'lodash.clonedeep'
// import {DefaultLanguage} from '../../model'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import MenuTabButton from "~/components/menu/menuTabButton"
import {useAssets} from '~/hooks/useAssets';
// import ImSelect from "~/components/common/ImSelect.vue"

export default {
  name: "LangSettingEdit",
  components: {
    // ImSelect,
    MenuTabButton,
    MenuItem,
    MenuSwitch,
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    siteLangModel: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const { langList, projectLangList } = useAssets(this);
    return {
      defaultLang: '',
      langList,
      projectLangList,
      sortIndexLangList: [],
      debounceEditList: '',
    }
  },
  computed: {
    // defaultLangList() {
    //   const result = [
    //     { label: '英语', value: DefaultLanguage, disabled: false }
    //   ]
    //   this.sortIndexLangList.forEach(item => {
    //     if (item.checked && item.value !== DefaultLanguage) {
    //       const newItem = {
    //         label: item.label,
    //         value: item.value,
    //         disabled: false
    //       }
    //       result.push(newItem)
    //     }
    //   })
    //   return result
    // }
  },
  watch: {
    // langList(data) {
    //   if (data) {
    //    this.makeSortIndex(data)
    //   }
    // }
  },
  async created() {
    this.debounceEditList = debounce(this.editLangList, 500)
    const { getLangs } = useAssets(this)
    await getLangs()
    this.initLangList()
  },
  methods: {
    initLangList() {
      const siteLangList = this.siteLangModel?.site?.langs || []
      this.langList.forEach(item =>{
        item.checked = siteLangList.includes(item.value)
      })
    },
    // async onChangeDefault() {
    //   const params = {
    //     code: this.defaultLang,
    //     site_builder_id: this.siteLangModel.baseInfo.siteId
    //   }
    //   this.siteLangModel.changeLang(params.code)
    //   await this.$api.siteBuild.changeDefaultLang(params)
    //   // this.makeSortIndex(this.langList)
    // },
    // makeSortIndex(data = []) {
    //   this.sortIndexLangList = []
    //   const sl = this.siteLangModel.site.langs || []
    //   // const projectLangList = this.projectLangList
    //   const defaultLang = this.defaultLang || this.siteLangModel.lang
    //   data.forEach((item, index) => {
    //     const newItem = {
    //       disabled: defaultLang === item.value,
    //       label: item.label,
    //       checked: sl.includes(item.value),
    //       value: item.value,
    //       __origin_index: index,
    //       __sort__index: sl.includes(item.value) ? 0 : index
    //     }
    //     if (newItem.disabled) {
    //       this.sortIndexLangList.unshift(newItem)
    //     } else {
    //       this.sortIndexLangList.push(newItem)
    //     }
    //   })
    //   const { setSiteLangList } = useAssets(this)
    //
    //   setSiteLangList(cloneDeep(this.sortIndexLangList))
    // },
    async editLangList() {
      const params = {
        codes: this.langList.filter(item => item.checked || item.isDefault).map(item => item.value),
        site_builder_id: this.siteLangModel.baseInfo.siteId
      }
      const [err] = await this.$utils.to(this.$api.lang.editList(params))
      if (err) {
        Message.error(err)
      }
    },
    switchChange(val, s) {
      s.checked = val
      this.debounceEditList()
    }
  }
}
</script>

<style scoped></style>
