<template>
<div class="notice-edit">
  <menu-item label="背景设置">
    <menu-fill :target="model.background" />
    <menu-background-image class="mt-2" :target="model.background" />
  </menu-item>
  <menu-item>
    <menu-switch v-model="model.enabled" label="是否开启公告" />
  </menu-item>
  <menu-item label="文字颜色">
    <menu-fill :target="model.textColor" />
  </menu-item>
  <menu-item label="内容">
    <el-input v-model="model.content" class="im-textarea" type="textarea" :rows="8" placeholder="请填写公告内容或语言ID"></el-input>
  </menu-item>
</div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  components: {MenuSwitch, MenuBackgroundImage, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>
