<template>
  <el-dialog v-loading="loading" class="order-dialog" :visible="$store.state.site.orderModal.visible" :show-close="false" destroy-on-close fullscreen width="100%" @open="onOpen">
    <div class="order-dialog__content">
      <div class="order-dialog__header flex justify-center items-center relative">
        <div class="text-16 text-black">{{ $t('siteBuild.orderInfo') }}</div>
        <i class="el-icon-close text-16 absolute right-5" @click="handleClose" />
      </div>
      <div class="order-dialog__body">
        <div v-if="payInfo.code" class="logo-area flex mt-[20px] items-center slide-up">
          <img v-if="projectIcon" :src="projectIcon" class="app-logo" alt />
          <div v-if="!projectIcon" class="flex items-center justify-center app_logo default_app_logo">
            <img src="~/assets/img/default-app.png" alt />
          </div>
          <span class="ml-5">{{projectName}}</span>
        </div>
        <div v-if="gameName && payInfo.code" class="game-name-area mb-[20px] slide-up">{{ $t('siteBuild.header.userAccount') }}：{{gameName}}</div>
        <div v-if="payInfo.total_amount" class="price-area mb-[20px] slide-up">
          <div class="pay_tit">{{ $t('siteBuild.totalAmount') }}</div>
          <div class="pay_total_amount">{{payInfo.currency}} {{payInfo.total_amount}}</div>
          <div class="pay_sub_total">{{ $t('siteBuild.subTotal') }}：{{payInfo.currency}} {{payInfo.before_total_amount}}</div>
          <div v-if="payInfo.is_discount_flag" class="pay_sub_total mt-2">{{ $t('siteBuild.fullDiscount') }}：{{ payInfo.discount_amount }}</div>
          <div v-if="payInfo && typeof payInfo.with_tax === 'boolean' && !payInfo.with_tax" class="pay_sub_total mt-[2px]">
            {{ $t('siteBuild.tax') }}：{{payInfo.currency}} {{payInfo.taxes_value ? payInfo.taxes_value.toFixed(2) : 0.00}}
          </div>
        </div>
        <div v-if="goodsData.length" class="goods-area slide-up">
          <div v-for="good in goodsData" :key="`good_${good.id}`" class="good_detial">
            <div class="mb-6">
              <div class="good_tit mb-2">{{$t('siteBuild.buyGoods')}}</div>
              <div class="good_item flex">
                <img
                  v-if="good.item_icon"
                  :src="good.item_icon"
                  class="w-[54px] h-[54px] rounded mr-4 good_logo"
                  alt
                />
                <div class="flex-1 flex flex-col justify-center">
                  <div class="good_name mb-1">{{ getGoodsName(good.names?.lang_id) }}</div>
                  <div class="good_price flex items-center">
                    <div class="w-[120px] mr-6">{{good.currency}} {{good.price}}</div>
                    <div>x{{good.num}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="good.activity && good.activity.length > 0">
              <div class="good_tit mb-2">{{$t('siteBuild.gift')}}</div>
              <div v-for="(item, index) in good.activity" :key="`${good.id}_${index}`">
                <div
                  v-for="(gift, idx) in item.gift_list"
                  :key="`${good.id}_${index}_${idx}`"
                  class="good_item flex mb-4"
                >
                  <img :src="gift.logo" alt class="w-[56px] h-[56px] rounded mr-4" />
                  <div class="flex-1 flex flex-col justify-center">
                    <div class="good_name mb-1">{{$t('siteBuild.gift')}}</div>
                    <div class="good_price flex items-center">
                      <div class="w-[120px] mr-6">{{good.currency}} 0.00</div>
                      <div>x{{gift.num}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="monthCardData && monthCardData.gift_packs_list.length > 0">
              <div class="good_tit mb-2">{{$t('siteBuild.gift')}}</div>
              <div
                v-for="(item, idx) in monthCardData.gift_packs_list"
                :key="`${good.id}_${item}_${idx}`"
                class="good_item flex mb-4"
              >
                <img :src="item.gift_goods_logo" alt class="w-[56px] h-[56px] rounded mr-4" />
                <div class="flex-1 flex flex-col justify-center">
                  <div class="good_name mb-1">{{$t('siteBuild.gift')}}</div>
                  <div class="good_price flex items-center">
                    <div>x{{item.num}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="handle-area">
        <div class="submit-btn" @click="handleToPay">Pay {{payInfo.currency}} {{payInfo.total_amount}}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Message } from 'element-ui'
export default {
  name: 'OrderModal',
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      loading: false,
      payInfo: {}
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale.locale || 'en'
    },
    project() {
      return this.$store.state.project || {}
    },
    projectIcon() {
      return this.project && this.project.info && this.project.info.project && this.project.info.project.icon ? this.project.info.project.icon : ''
    },
    projectName() {
      const names = this.project && this.project.info && this.project.info.project && this.project.info.project.names ? this.project.info.project.names : {}
      return names[this.locale] ? names[this.locale] : names.en ? names.en : ""
    },
    gameName() {
      return this.$store.state.user.siteUserInfo && this.$store.state.user.siteUserInfo.name || ''
    },
    goodsData() {
      return this.payInfo && this.payInfo.items ? this.payInfo.items : []
    },
    monthCardData() {
      return this.$store.state.site.orderModal.monthCardData
    }
  },
  methods: {
    getGoodsName(name) {
      return this.$store.state.locale.langIdData[name] || name
    },
    handleClose() {
      this.$store.commit('site/CLOSE_ORDER_MODAL')
    },
    async getOrderDetail() {
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.order.getOrderInfo({ code: this.$store.state.site.orderModal.order }))
      this.loading = false
      if (!err) {
        this.payInfo = res
      } else {
        Message.error(err.message)
      }
    },
    onOpen() {
      this.payInfo = {}
      this.getOrderDetail()
    },
    handleToPay() {
      window.open(this.$store.state.site.orderModal.payUrl, '_self')
    }
  }
}
</script>

<style lang="less">
.order-dialog {
  .slide-down {
    opacity: 0;
    animation: slideDown 0.24s linear forwards;
  }
  .slide-up {
    opacity: 0;
    animation: slideUp 0.24s linear forwards;
  }
  @keyframes slideUp {
    from {
      transform: translateY(100px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes slideDown {
    from {
      transform: translateY(-100px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .el-dialog {
    border-radius: 0;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  &__header {
    line-height: 48px;
    border-bottom: 1px solid #dedede;
  }
  &__body {
    max-height: calc(100vh - 50px);
    padding-bottom: 88px;
    overflow: auto;
  }
  &__content {
    .logo-area {
      padding: 20px;
      .app-logo {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }
    .game-name-area {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0;
      color: #18171c;
      padding: 8px 12px;
      border-radius: 2px;
      background-color: rgba(61, 70, 245, 0.1);
      animation-delay: 100ms;
      margin: 0 20px;
    }
    .price-area {
      font-family: PingFang SC;
      font-weight: normal;
      color: #18171c;
      padding: 0 20px;
      animation-delay: 300ms;
      .pay_tit {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
      }
      .pay_total_amount {
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
      }
      .pay_sub_total {
        font-size: 14px;
        line-height: 22px;
        opacity: 0.5;
      }
    }
    .goods-area {
      animation-delay: 500ms;
      padding: 0 20px;
      .good_tit {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: #18171c;
      }

      .good_item {
        font-family: PingFang SC;
        letter-spacing: 0px;
        color: #18171c;
        .good_name {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
        }
        .good_price {
          font-size: 14px;
          line-height: 22px;
          opacity: 0.5;
        }
      }

      .default_good_logo {
        border: 1px solid #ebeef1;
        background: #ffffff;
      }
      .good_logo {
        border: 1px solid #ebeef1;
      }
    }
    .handle-area {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #dedede;
      background-color: white;
      .submit-btn {
        height: 48px;
        background-color: @primary-1;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        font-size: 16px;
      }
    }
  }
}
</style>
