<template>
<!--  <assets-material :in-widget="false" :upload-text="$t('upload.text')" :limit="5"></assets-material>-->
  <assets-library ref="assets" tip="上传图片大小不超过5M" scroll-height="auto" :limit="5"/>
</template>

<script>
import AssetsLibrary from '~/site/components/assetsLibrary/index.vue'
export default {
  name: "AssetsSettingEdit",
  components:{
    // AssetsMaterial,
    AssetsLibrary
  },
  props:{
    model: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$refs.assets.init()
    }
  }
}
</script>

<style scoped>

</style>
