<template>
  <client-only>
    <div class="color-model__picker">
      <eye-dropper ref="dropper" @change="onDropperChange" @mount="onDropperMount" />
      <color-picker v-model="color" :preset-colors="recentColors" @input="onInput" />
    </div>
  </client-only>
</template>

<script>
import { Chrome } from 'vue-color'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import EyeDropper from "~/components/common/EyeDropper"
export default {
  components: {
    ColorPicker: Chrome,
    EyeDropper
  },
  props: {
    value: [String]
  },
  data() {
    return {
      color: '',
      debounceSetRecentColor: ''
    }
  },
  computed: {
    ...mapGetters(['recentColors'])
  },
  watch: {
    value(n) {
      this.color = n
    }
  },
  mounted() {
    this.color = this.value || ''
    this.debounceSetRecentColor = debounce(this.setRecentColor, 500)
  },
  methods: {
    onDropperMount() {
      this.$nextTick(() => {
        const timer = setTimeout(() => {
          document.querySelector('.color-model__picker .vc-chrome-color-wrap').append(this.$refs.dropper.$el)
          clearTimeout(timer)
        }, 0)
      })
    },
    getValue(color) {
      const { r, g, b, a } = color.rgba
      return `rgba(${r},${g},${b}, ${a})`
    },
    setRecentColor(value) {
      this.$store.commit('theme/SET_RECENT_COLORS', value)
    },
    onInput(color) {
      const rgba = this.getValue(color)
      this.$emit('input', rgba)
      this.debounceSetRecentColor && this.debounceSetRecentColor(rgba)
    },
    onDropperChange(color) {
      this.$emit('input', color)
    }
  }
}
</script>

<style lang="less">
.color-model__picker {
  .vc-chrome {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    .vc-chrome-color-wrap {
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .vc-chrome-toggle-icon {
      path {
        fill: @text-7;
      }
    }
    .vc-chrome-toggle-icon-highlight {
      background-color: @fill-color-2;
    }
    .vc-chrome-hue-wrap {
      height: 8px;
    }
    .vc-alpha-checkboard-wrap {
      border-radius: 10px;
    }
    .vc-chrome-hue-wrap .vc-hue {
      border-radius: 10px;
    }
    .vc-chrome-alpha-wrap .vc-alpha-gradient {
      border-radius: 10px;
    }

    .vc-chrome-hue-wrap .vc-hue-picker, .vc-chrome-alpha-wrap .vc-alpha-picker {
      border: 3px solid white;
      background-color: transparent;
      transform: translate(-6px, -3px);
    }
    .eye-dropper {
      width: calc(100% - 5px);
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 10;
      background-color: @widget-menu-bg;
    }
    .vc-checkerboard {
      //z-index: -1;
      //left: 9999px;
    }
    .vc-chrome-active-color {
      width: 0;
      height: 0;
    }
    .vc-chrome-saturation-wrap {
      height: 160px;
    }
    .vc-chrome-body {
      background-color: @widget-menu-bg;
      padding: 12px 2px 0 2px;
    }
    .vc-chrome-alpha-wrap {
      height: 8px;
    }
    .vc-chrome-fields {
      .vc-input__input {
        background-color: @fill-color-2;
        color: @text-5;
        border-color: @fill-color-2;
        box-shadow: none;
      }
      .vc-input__label {
        color: @text-7;
      }
    }

  }
}
</style>
