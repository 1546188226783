// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".color-model__gradient .thumb-area{border-radius:24px;box-shadow:inset 0 0 0 .0625rem rgba(0,0,0,.071),inset 0 .0625rem .1875rem 0 rgba(0,0,0,.149);cursor:crosshair;height:24px;left:0;position:absolute;top:0;width:100%;z-index:100}.color-model__gradient .thumb-area .color-item{height:24px;padding:3px;width:24px}.color-model__gradient .thumb-area .color-item__circle{border:4px solid #fff;border-radius:100%;height:100%;position:relative;width:100%}.color-model__gradient .thumb-area .color-item.active .color-item__circle:after{border:3px solid #3f54f2;border-radius:100%;content:\"\";height:24px;left:-7px;position:absolute;top:-7px;width:24px}.color-model__gradient .handle-action .el-icon-minus,.color-model__gradient .handle-action .el-icon-plus{cursor:pointer}.color-model__gradient .handle-action .el-icon-minus.is-disabled,.color-model__gradient .handle-action .el-icon-plus.is-disabled{color:hsla(0,0%,100%,.5);cursor:not-allowed}.color-model__gradient .color-bar{background-position:0 0,10px 10px;background-size:20px 20px;border-radius:24px;height:24px}.color-model__gradient .color-bar__inner{background:var(--osw-slider-background);border-radius:24px;box-shadow:inset 0 0 0 .0625rem rgba(0,0,0,.071),inset 0 .0625rem .1875rem 0 rgba(0,0,0,.149)}.color-model__gradient .color-mark{height:20px}.color-model__gradient .color-mark .mark-item{height:20px;width:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
