<template>
  <el-dialog class="month-card-result" :visible.sync="visible" :class="{ 'mobile-edit': editing && $store.getters.isMobile }" :modal="!editing" :modal-append-to-body="false" :width="$store.getters.isMobile ? mobileWidth : '520px'">
    <div class="content">
      <div class="title mb-4 text-center text-20">{{ $t('siteBuild.monthCardWidget.congratulate') }}</div>
      <div class="list mt-8 flex flex-wrap justify-center items-center">
        <bg-style v-for="(item, index) in list" :key="index" class="item" :bg="model.prizeBackground">
          <img class="max-w-[80%] max-h-[80%] relative" :src="item.gift_goods_logo" alt="">
          <bg-style class="number" :bg="model.prizeNumberBackground">
            <span class="relative">{{ item.num }}</span>
          </bg-style>
        </bg-style>
      </div>
      <bg-style class="ok-button mx-auto flex mt-[36px] h-[44px] items-center justify-center cursor-pointer w-[140px]" :bg="model.buttonBackground" @click.native="cancel">
        <span class="relative">{{ $t('siteBuild.confirm') }}</span>
      </bg-style>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";

export default {
  name: 'MonthCardRule',
  components: {
    [Dialog.name]: Dialog
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    mobileWidth() {
      return (this.editing && this.$store.getters.isMobile) ? '320px' : '90%'
    }
  },
  methods: {
    cancel() {
      this.visible = false
    },
    init() {
      this.visible = true
    }
  }
}
</script>

<style lang="less">
.month-card-result {
  &.mobile-edit {
    .el-dialog {
      left: 10px;
    }
  }
  .rich-text.focus-editor .ql-container.ql-snow {
    background-color: transparent;
  }
  .title {
    color: var(--text-color);
  }
  .ok-button {
    color: var(--button-color);
  }
  .list {
    gap: 20px;
    .item {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      .number {
        position: absolute !important;
        width: 100%;
        line-height: 16px;
        bottom: 0;
        left: 0;
        text-align: center;
        font-size: 12px;
        color: var(--prize-number-color);
      }
    }
  }
  .el-dialog__header {
    font-weight: bold;
    text-align: center;
    padding: 0;
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
    .el-dialog__title {
      font-size: 18px;
    }
  }
}
</style>
