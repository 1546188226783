// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".goods-detail .el-dialog,.goods-detail .el-drawer{background:transparent none repeat 0 0/auto auto padding-box border-box scroll;background:initial}.goods-detail .el-drawer__header{display:none}.goods-detail .el-drawer__body{max-height:95vh;overflow:auto}.goods-detail .el-drawer__body::-webkit-scrollbar{width:0}.goods-detail .el-drawer__body .goods-detail__content{padding:52px 20px 38px}.goods-detail .el-dialog__header{display:none}.goods-detail .el-dialog__body{padding:0}.goods-detail__content{border-radius:4px 4px 0 0;overflow:hidden;padding:64px 50px 36px}.goods-detail__close{cursor:pointer;font-size:20px;font-weight:600;position:absolute;right:24px;top:24px;z-index:10}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
