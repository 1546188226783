// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quick-gradient__list{-moz-column-gap:13px;column-gap:13px;display:flex;flex-wrap:wrap;margin-top:10px;row-gap:10px}.quick-gradient__list .item{border:1px solid #fff;border-radius:100%;box-shadow:inset 0 0 0 .0625rem rgba(0,0,0,.2);cursor:pointer;flex-shrink:0;height:40px;width:40px}.quick-gradient__list .item:hover{border-color:#000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
