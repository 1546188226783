<template>
  <div class="carousel-menu-wrap">
    <menu-title :close="close">绑定角色</menu-title>
    <menu-item class="menu-item-switch" label="描述背景图">
      <div class="custom-back-img">
        <menu-background-image class="mt-2" image-key="helperImage" event-key="show-help-image" eye-key="helperImageShow" :target="model" />
      </div>
    </menu-item>
    <menu-item label="服务器信息">
      <menu-switch v-model="model.serverEnabled" label="是否启用" />
    </menu-item>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import menuTitle from '~/components/menu/menuTitle';
import MenuItem from '~/components/menu/menuItem';
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  name: 'EditMenuCollapse',
  components: {
    MenuSwitch,
    MenuItem,
    menuBackgroundImage,
    menuTitle,
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    ...mapMutations({
      'ADD_MENU_KEY': 'menu/ADD_MENU_KEY',
      'REMOVE_MENU_KEY': 'menu/REMOVE_MENU_KEY'
    }),
    close() {
      this.REMOVE_MENU_KEY('bind-user')
      this.$nextTick(() => {
        this.SiteMenu.close('bind-user')
      })
    },
  }
}
</script>
