// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".color-model__rotate{--p-color-bg-surface:#fff;--p-color-bg-surface-secondary:#f7f7f7;--p-space-400:1rem}.color-model__rotate .preview-rotate{align-items:center;border-radius:4px;display:flex;height:96px;justify-content:center;margin-bottom:12px;position:relative;width:100%}.color-model__rotate .preview-rotate:after{background:var(--osw-gradient-background);border-radius:4px;box-shadow:inset 0 0 0 .0625rem rgba(0,0,0,.2);content:\"\";height:100%;left:0;position:absolute;top:0;width:100%}.color-model__rotate .color-model__edg .im30-site-builder-input{position:relative}.color-model__rotate .color-model__edg .im30-site-builder-input:after{color:#fff;content:\"。\";position:absolute;right:10px;top:-5px}.color-model__rotate .color-model__edg .im30-site-builder-input .el-input__inner{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
