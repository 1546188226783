// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".color-model{background-color:#1e1e1e;width:220px}.color-model .vc-chrome-fields-wrap{display:none}.color-model__header .im-select .el-input__inner{background-color:#1e1e1e;border-color:hsla(0,0%,100%,.5)}.color-model__header .close-btn,.color-model__header .im-select:hover .el-input__inner{background-color:hsla(0,0%,100%,.05)}.color-model__header .close-btn{font-weight:700;height:18px;width:18px}.color-model__recent .color-item{height:20px;width:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
