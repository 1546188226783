<template>
  <el-dialog class="lottery-rule" :class="{ 'mobile-edit': editing && $store.getters.isMobile }" :width="$store.getters.isMobile ? mobileWidth : '520px'" :modal="!editing" :visible.sync="visible">
    <div class="lottery-rule__content">
      <rich-text v-model="model.ruleTitle" class="title mb-4" :editing="editing"/>
      <rich-text v-model="model.rule1" :editing="editing" />
      <rich-text v-model="model.rule2" :editing="editing" />
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui'
import DOMPurify from 'dompurify'
import RichText from "~/components/richText/index.vue";
export default {
  name: "LotteryRule",
  components: {
    RichText,
    [Dialog.name]: Dialog
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      DOMPurify,
      visible: false
    }
  },
  computed: {
    mobileWidth() {
      return (this.editing && this.$store.getters.isMobile) ? '320px' : '90%'
    }
  },
  methods: {
    init() {
      this.visible = true
    }
  }
}
</script>

<style lang="less">
.lottery-rule {
  &.mobile-edit {
    .el-dialog {
      left: 10px;
    }
  }
  .rich-text.focus-editor .ql-container.ql-snow {
    background-color: transparent;
  }
  .el-dialog__header {
    font-weight: bold;
    text-align: center;
    padding: 0;
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
    .el-dialog__title {
      font-size: 18px;
    }
  }
}
</style>
