<template>
  <div class="text-widget-edit">
    <menu-item label="背景设置">
      <menu-background-image :target="model.background" target-key="background" />
      <menu-fill class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import RemoveBtn from "~/components/common/RemoveBtn.vue";

export default {
  name: "TextWidgetEdit",
  components: {RemoveBtn, MenuBackgroundImage, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
