<template>
  <div class="widget-imageText">
    <menu-title :close="close">{{ targetTitle }}</menu-title>
    <menu-item class="mt-16px" :label="$t('menu.image')" size-tip="(建议比例4:3)">
      <menuBackgroundImage :target="target"  :eye-key="'backgroundImageShow'" event-key="show-article-image" />
    </menu-item>
    <nav-edit class="mt-6" :site="site" :target="target" />

    <menu-item :border-bottom="false" class="menu-item-switch" :label="$t('menu.layout')">
      <div class="switchs">
        <menu-switch :value="target.titleShow" :label="$t('menu.itemTitle')" @change="e=> target.titleShow = e"></menu-switch>
      </div>
       <div class="switchs">
        <menu-switch :value="target.descriptionShow" :label="$t('menu.describe')" @change="e=> target.descriptionShow = e"></menu-switch>
      </div>
    </menu-item>
    <DeleteBlockDialogTrigger class="mt-4" @delete-block="deleteBlock" />
  </div>
</template>

<script>
// 属性编辑
import cloneDeep from "lodash.clonedeep";
import { ImageTextItemWidgetModel } from '~/site/model/models/imageTextItem'
import NavEdit from "~/site/components/navEdit.vue";
import MenuItem from '~/components/menu/menuItem'
import MenuSwitch from '~/components/menu/menuSwitch'
import menuTitle from '~/components/menu/menuTitle'
// import BaseInput from '~/components/base/baseInput'
import menuBackgroundImage from '~/components/menu/menuBackgroundImage.vue'
import DeleteBlockDialogTrigger from '~/site/components/editMenu/deleteBlockTrigger'
import {insertStep} from "~/utils/event";

export default {
  name: 'ImageTextItemWidget',
  components: {
    MenuItem,
    NavEdit,
    MenuSwitch,
    menuTitle,
    // BaseInput,
    menuBackgroundImage,
    DeleteBlockDialogTrigger,
  },
  props: {
    childIndex: {
      type: [Number, undefined],
      default() {
        return undefined
      },
    },
    model: {
      type: [Object, undefined],
      default() {
        return undefined
      },
    },
    target: {
      type: Object,
      default() {
        return new ImageTextItemWidgetModel()
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    targetTitle() {
      return `${this.$t('model.imageTextItem')}${
        this.childIndex === undefined ? '' : this.childIndex + 1
      }`
    },
  },
  methods: {
    deleteBlock() {
      insertStep(this, ['children'], cloneDeep(this.model.children))
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
      const child = this.model?.children
      if (child) {
        const findIndex = child.findIndex((c) => c === this.target)
        if (findIndex >= 0) {
          child.splice(findIndex, 1)
        }
      }
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
      this.$emit('delete-block', this.target)
      this.close()
    },
    close() {
      this.SiteMenu.close()
    },
    openThreeMenu(key, target) {
      this.SiteMenu.open({
        key,
        target,
        model: this.model
      })
    },
    openSubMenu(key, target) {
      this.SiteMenu.open({
        key,
        target,
        model: this.model
      })
    },
    change() {},
  },
}
</script>
