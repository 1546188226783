// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".month-card-rule.mobile-edit .el-dialog{left:10px}.month-card-rule .rich-text.focus-editor .ql-container.ql-snow{background-color:transparent}.month-card-rule .el-dialog__header{font-weight:700;padding:0;text-align:center}.month-card-rule .el-dialog__header .el-dialog__headerbtn{right:10px;top:10px}.month-card-rule .el-dialog__header .el-dialog__title{font-size:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
