<template>
  <div class="consecutive-widget">
    <widget-content
      :class="isMobile ? 'activity_content_mobile relative' : 'activity_content_desk relative'"
      :bg="model.background"
    >
      <div
        v-if="model.titleVisible"
        :class="['flex items-center relative justify-center', !isMobile ? 'mb-[8px]' : 'mb-[4px]', {'px-[32px]': !isMobile}, {'px-[8px]': isMobile}]"
      >
        <rich-text
          v-model="model.title"
          :editing="editing"
          :stroke.sync="model.titleStroke"
          :disabled="!editing"
          placement="top"
          theme="snow"
          class="w-full activity_title title"
        ></rich-text>
      </div>
      <div
        v-if="model.subtitleVisible"
        :class="['flex items-center relative justify-center', !isMobile ? 'mb-[30px]' : 'mb-[16px]',{'px-[32px]': !isMobile}, {'px-[8px]': isMobile}]"
      >
        <rich-text
          v-model="model.subtitle"
          :stroke.sync="model.subTitleStroke"
          :editing="editing"
          :disabled="!editing"
          placement="top"
          theme="snow"
          class="w-full activity_desc title"
        ></rich-text>
      </div>
      <content-component :model="model" :editing="editing" :is-mobile="isMobile" />
    </widget-content>
  </div>
</template>

<script>
import ContentComponent from './content.vue'
import WidgetContent from '~/site/components/widgetContent'
import richText from '~/components/richText'

export default {
  name: 'ActivityWidgetEdit',
  components: {
    richText,
    WidgetContent,
    ContentComponent
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      //
    }
  },
  computed: {
    showTitle() {
      const res = this.model.layout.find((m) => {
        return m.key === 'activityTitle'
      })
      return res && res.value
    },
    isMobile() {
      return this.device === 'mobile'
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .widget-content > div:last-child {
  max-width: 1212px;
}
.consecutive-widget {
  .activity_content_desk {
    padding: 86px 0;

    .activity_title {
      font-size: 46px;
      line-height: 70px;
      // text-transform: uppercase;
    }

    .activity_desc {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .activity_content_mobile {
    padding: 32px 0 32px 16px;

    .activity_title {
      font-size: 24px;
      line-height: 36px;
      // text-transform: uppercase;
    }

    .activity_desc {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>
