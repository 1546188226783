<template>
  <el-dialog class="lottery-history" :class="{ 'mobile-edit': editing && $store.getters.isMobile }" :width="$store.getters.isMobile ? mobileWidth : '520px'" :visible.sync="visible" :modal="!editing">
    <div>
      <rich-text v-model="model.historyTitle" :editing="editing" />
      <div class="lottery-history__content mt-5">
        <div class="list">
          <div v-for="(item, index) in list" :key="index" class="item flex items-center justify-between">
            <div class="flex items-center">
              <span class="mr-6">{{ index + 1 }}</span>
              <img class="w-[40px] h-[40px]" :src="item.gift_goods_logo" alt="">
              <i class="el-icon-close mx-2" />
              <span>{{ item.num }}</span>
            </div>
            <div class="time">
              <span class="ml-2">{{ item.lottery_time }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui'
import { formatZoneTime } from '~/utils'
import RichText from "~/components/richText/index.vue";

export default {
  components: {
    RichText,
    [Dialog.name]: Dialog
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      list: [],
      visible: false
    }
  },
  computed: {
    mobileWidth() {
      return (this.editing && this.$store.getters.isMobile) ? '320px' : '90%'
    }
  },
  methods: {
    async init() {
      await this.getHistory()
      this.visible = true
    },
    async getHistory() {
      if (this.editing) return
      const params = {
        activity_id: this.model.bizId,
        page_no: 1,
        page_size: 1000
      }
      const [err, res] = await this.$utils.to(this.$api.activity.historyList(params))
      if (!err && res.list) {
        this.list = res.list.map(item => {
          const newItem = {...item}
          newItem.lottery_time = formatZoneTime(newItem.lottery_time * 1000).text
          return newItem
        })
      }
    }
  }
}
</script>


<style lang="less">
.lottery-history {
  .rich-text.focus-editor .ql-container.ql-snow {
    background-color: transparent;
  }
  .el-dialog__header {
    font-weight: bold;
    text-align: center;
    padding: 0;
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
    .el-dialog__title {
      font-size: 18px;
    }
  }
  &.mobile-edit {
    .el-dialog {
      left: 10px;
    }
  }
  &__content {
    .list {
      max-height: 400px;
      overflow-y: auto;
      .item {
        background-color: #F6F6F6;
        margin-bottom: 8px;
        padding: 12px;
        font-size: 12px;
      }
    }
  }
}
</style>
