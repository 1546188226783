// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-popper{background-color:transparent;border:0;border-radius:0;padding:0}.header-popper .item{border-bottom:1px solid var(--header-text-color);cursor:pointer;font-family:PingFang SC;font-size:14px;line-height:44px;padding-left:15px;text-align:left;transition:all .3s ease}.header-popper .item:last-of-type{border-bottom:0}.header-popper .item:hover{padding-left:30px}.header_avatar_popover{border:1px solid #ebeef1;border-radius:2px;padding:4px;width:210px}.header_avatar_popover .header_avatar_item{color:#18171c;font-family:PingFangSC-Regular;margin-bottom:2px}.header_avatar_popover .header_avatar_item:hover{background-color:rgba(235,238,241,.6)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
