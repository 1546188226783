<template>
  <div class="relative">
    <!--start 绑定账号页图片，有图显示图片-->
    <div
      v-if="model.helperImage && model.helperImageShow"
      class="relative flex justify-center items-center"
    >
      <img :src="model.helperImage" class="image rounded" alt="assets" />
      <CoverBlock
        v-if="editing"
        absolute
        :title="$t('edit.setImage')"
        @click="handleShowBackgroundMenu(model)"
      />
    </div>
    <!--start 绑定账号页图片，无图设置图片-->
    <div v-if="editing && !model.helperImage" class="setting-image">
      <CoverBlock :title="$t('edit.setImage')" @click="handleShowBackgroundMenu(model)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelperImage",
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleShowBackgroundMenu(child) {
      const menu = {
        key: 'bind-user',
        target: child,
        model: child,
      }
      this.$nextTick(() => {
        this.SiteMenu.openMenuShowImage(menu, 'show-help-image')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.setting-image {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hepler-image {
  width: 100%;
  min-height: 100px;
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background-color: #DEDFE0;
  }
}
</style>
