<template>
  <div ref="bar" class="rotate-bar relative z-10" :style="getStyle">
    <svg class="rotate-bar__svg" width="75px" height="75px" viewBox="0 0 75 75" aria-hidden="true">
      <g>
        <circle ref="circle1" class="rotate-bar__svg--circle" r="27.5" cx="37.5" cy="37.5" style="--circle-stroke-width:2;"></circle>
        <filter id="dropShadow" filterUnits="userSpaceOnUse">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3"></feGaussianBlur>
          <feOffset dx="2" dy="2"></feOffset>
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.3"></feFuncA>
          </feComponentTransfer>
          <feMerge>
            <feMergeNode></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <circle ref="circle2" class="rotate-bar__svg--circle2" r="5" cx="37.5" cy="65" @mousedown="onmousedown"></circle>
      </g>
    </svg>
  </div>
</template>

<script>
import { off, on } from "~/utils/dom";
export default {
  name: 'RotateBar',
  props: {
    value: [String, Number]
  },
  data() {
    return {
      current: {
        x: null,
        y: null,
        width: null,
        height: null,
        rotate: 0
      },
      displayRotate: 0,
      originCenter: {
        x: null,
        y: null
      }
    }
  },
  computed: {
    getStyle() {
      return {
        transform: `rotate(${this.current.rotate}deg)`
      }
    }
  },
  mounted() {
  },
  methods: {
    getRect() {
      const { x, y, width, height } = this.$refs.bar.getBoundingClientRect()
      Object.assign(this.current, { x, y, width, height })
      this.originCenter = {
        x: this.current.x + (this.current.width / 2),
        y: this.current.y + (this.current.height / 2)
      }
      console.log(this.current, this.originCenter)
    },
    setEdg(value) {
      this.current.rotate = value >= 360 ? 360 : (value || 0)
    },
    spinning() {
      this.$emit('spin', this.displayRotate)
    },
    dragMove(event) {
      event.preventDefault()
      const currentPosition = { x: event.clientX, y: event.clientY }
      const originCenter = this.originCenter
      const a = Math.abs(currentPosition.x - this.originCenter.x)
      const b = Math.abs(currentPosition.y - this.originCenter.y)
      const c = Math.sqrt(a * a + b * b)
      let rotate = Math.round((Math.asin(b / c) / Math.PI * 180))
      if (currentPosition.x >= originCenter.x && currentPosition.y <= originCenter.y) {
        rotate = 90 - rotate
      } else if (currentPosition.x <= originCenter.x && currentPosition.y <= originCenter.y) {
        rotate = 270 + rotate
      } else if (currentPosition.x <= originCenter.x && currentPosition.y >= originCenter.y) {
        rotate = 270 - rotate
      } else if(currentPosition.x >= originCenter.x && currentPosition.y >= originCenter.y) {
        rotate = 90 + rotate
      }
      this.current.rotate = rotate === 360 ? 0 : Number(rotate) - 180
      this.displayRotate = (rotate + 180) >= 360 ? rotate - 180 : rotate + 180
      this.spinning()
    },
    dragEnd() {
      const that = this
      off(document.body, 'mousemove', that.dragMove)
      off(document.body, 'mouseup', that.dragEnd)
    },
    onmousedown() {
      const that = this
      that.getRect()
      on(document.body, 'mousemove', that.dragMove)
      on(document.body, 'mouseup', that.dragEnd)
    }
  }
}
</script>

<style lang="less">
.rotate-bar {
  --circle-stroke-width: 2px;
  opacity: 0.4;
  transition: opacity 0.3s linear;
  &:hover {
    opacity: 1;
  }
  &__svg {
    &--circle {
      fill: none;
      filter: drop-shadow(0 .0625rem .125rem rgba(0,0,0,.32));
      stroke-width: var(--circle-stroke-width);
      stroke: rgb(255, 255, 255);
    }
    &--circle2 {
      fill: rgb(255, 255, 255);
      cursor: pointer;
      filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 1px 2px);
    }
  }
}
</style>
