<template>
  <div ref="colorModel" class="color-model rounded">
    <div class="color-model__header flex items-center justify-between border-b p-2 border-fillColor-2">
      <im-select v-model="type" :options="typeOptions" class="w-2/4" size="small" @change="onChangeType"/>
      <i class="el-icon-close cursor-pointer text-18 text-white" @click="handleClose"/>
    </div>
    <div class="color-model__content px-3 py-3">
      <quick-gradient v-if="isPickQuickColor" @select="onQuickSelect"/>
      <gradient v-else-if="type === 'gradient'" ref="gradient" v-model="color" :parent-rect="rect" class="mb-3" @cancel="onGradientCancel" @change="onGradientChange" @pure-change="onGradientPureChange"/>
      <pure v-if="type === 'pure'" v-model="color" @change="onPureChange"/>
    </div>
    <color-transform v-if="!isPickQuickColor" :value="transformValue"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import colorTransform from './transform.vue'
import QuickGradient from './quick.vue'
import gradient from './gradient'
import pure from "./pure";

export default {
  components: {
    gradient,
    colorTransform,
    pure,
    QuickGradient
  },
  props: {
    value: [String]
  },
  data() {
    return {
      type: '',
      color: '',
      quickColor: '',
      transformValue: '',
      rect: {
        bottom: null,
        height: null,
        left: null,
        right: null,
        top: null,
        width: null,
        x: null,
        y: null
      },
      typeOptions: [
        {
          label: '纯色',
          value: 'pure'
        },
        {
          label: '渐变',
          value: 'gradient'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['recentColors']),
    isPickQuickColor() {
      return this.type === 'gradient' && !this.color?.includes('linear')
    }
  },
  watch: {
    value(n) {
      this.color = n
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    // handleClickRecent(value) {
    //   if (this.type === 'pure') {
    //     this.color = value
    //     this.emitValue(value)
    //   } else if (this.type === 'gradient') {
    //     this.$refs.gradient.setCurrentValue(value)
    //   }
    // },
    init() {
      const rect = this.$refs.colorModel.getBoundingClientRect()
      for (const key in rect) {
        if (Object.prototype.hasOwnProperty.call(this.rect, key)) {
          this.rect[key] = rect[key]
        }
      }
      this.initValue()
    },
    onChangeType() {
      this.color = this.value
    },
    initValue() {
      this.transformValue = this.value
      this.color = this.value
      this.getValueType()
    },
    getValueType() {
      const linearKey = 'linear'
      const isLinear = this.value.includes(linearKey)
      if (isLinear) {
        this.type = 'gradient'
      } else {
        this.type = 'pure'
      }
    },
    handleClose() {
      this.$emit('close')
    },
    emitValue(value) {
      this.$emit('change', this.type, value)
    },
    onGradientPureChange(value) {
      this.transformValue = value
    },
    onGradientChange(value) {
      this.transformValue = value
      this.emitValue(value)
    },
    onGradientCancel() {
      this.color = ''
      this.emitValue('')
    },
    onQuickSelect(item) {
      this.color = item.color
      this.transformValue = item.color
      this.emitValue(item.color)
    },
    onPureChange(value) {
      this.transformValue = value
      this.emitValue(value)
    }
  }
}
</script>

<style lang="less">
.color-model {
  background-color: @widget-menu-bg;
  width: 220px;

  .vc-chrome-fields-wrap {
    display: none;
  }

  &__header {
    .im-select {
      .el-input__inner {
        background-color: @widget-menu-bg;
        border-color: @text-3;
      }

      &:hover {
        .el-input__inner {
          background-color: @fill-color-2;
        }
      }
    }

    .close-btn {
      width: 18px;
      height: 18px;
      background-color: @fill-color-2;
      font-weight: bold;
    }
  }

  &__recent {
    .color-item {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
