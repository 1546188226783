<template>
  <bg-style class="store-list p-5 relative" :bg="model.background" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }">
    <div class="store-list__content">
      <rich-text v-model="model.title" :visible="model.titleVisible" :stroke.sync="model.titleStroke" :editing="editing" :class="$store.getters.isDesktop && 'xl:text-[40px]'" class="text-[20px] w-full relative title mt-8" theme="snow" :disabled="!editing" />
      <rich-text v-model="model.subtitle" :visible="model.subtitleVisible" :stroke.sync="model.subTitleStroke" :editing="editing" class="mt-3 relative text-[20px]" theme="snow" :disabled="!editing" />
      <div class="type-area">
        <div v-for="(item, index) in classList" :key="index" class="item whitespace-nowrap overflow-hidden text-ellipsis" @click="handleChangeType(index)">
          {{ item.label }}
          <div v-if="current === index" class="line" />
        </div>
      </div>
      <div v-imloading="loading" class="goods-area relative mt-8">
        <div v-if="!loading && !goodsList.length" class="flex w-full items-center justify-center">
          <el-empty class="relative" description=" " />
        </div>
        <div v-for="(item, index) in goodsList" :key="item.id" class="item">
          <bg-style class="flex items-center relative p-4 rounded h-full cursor-pointer item-content overflow-hidden" :bg="(currentGoodsId === item.id || moveIndex === index) ? model.btnBackground : model.goodsBackground" @mouseout.native="ouMouseOut" @mousemove.native="onMouseIn(index)" @click.native="handleSelect(item)">
            <div class="left-info w-1/2 relative">
              <div class="text-18" :style="getTextColor(model.nameBackground)" :title="item.names.name">{{ item.names.name }}</div>
              <div class="text-14 whitespace-nowrap overflow-hidden text-ellipsis" :style="getTextColor(model.descBackground)" :title="item.descriptions.desc">{{ item.descriptions.desc }}</div>
            </div>
            <div class="right-price w-1/2 text-center text-20 relative" :style="getTextColor(model.priceBackground)">
              {{ item.priceInfo.priceTxt }}
            </div>
          </bg-style>
        </div>
      </div>
      <div class="handle-area mt-8 rounded flex w-full items-center justify-between">
        <div class="left-price relative flex items-start justify-center flex-col">
          <div class="text-20">
            {{ $t('siteBuild.totalAmount') }}:<span class="ml-2 text-28">{{ currentGoods.priceInfo?.priceTxt }}</span>
          </div>
          <div class="text-14 whitespace-nowrap overflow-hidden text-ellipsis" :class="$store.getters.isMobile ? 'max-w-[200px]' : 'max-w-[600px]'">
            <span v-if="currentGoods?.names" :title="currentGoods?.names?.name">{{ currentGoods.names?.name }}：</span>
            <span :title="currentGoods.descriptions?.desc">{{ currentGoods.descriptions?.desc }}</span>
          </div>
        </div>
        <bg-style class="buy cursor-pointer px-5 flex-shrink-0 py-2 flex items-center justify-center" :bg="model.btnBackground" @click.native="handleSubmit">
          <div class="flex items-center">
            <i v-if="orderLoading" class="el-icon-loading mr-2" />
            <div class="relative text-20" :style="getTextColor(model.nameBackground)">{{ $t('siteBuild.shopCart.buy') }}</div>
          </div>
        </bg-style>
      </div>
    </div>
    <DeskPayModal
      v-if="deskPay.visible"
      :desk-pay="deskPay"
      :store-lang="storeLang"
      :lang-id-data="$store.state.locale.langIdData"
      @close="closePay"
    />
  </bg-style>
</template>

<script>
import { Message, Empty } from 'element-ui'
import cloneDeep from "lodash.clonedeep";
import RichText from "~/components/richText/index.vue";
import tools, {checkIgnoreMessage, checkUser, generateOrderParams, generatePayUrl, getTextColor} from "~/utils";
import {DeviceEnum} from "~/enums/deviceEnum";
import Bus from '~/site/model/bus'
import DeskPayModal from "~/components/pay/deskPayModal.vue";
import {DefaultLanguage} from "~/site/model";

export default {
  name: 'StoreListWidget',
  components: {
    DeskPayModal,
    RichText,
    [Empty.name]: Empty
  },
  props: {
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      current: 0,
      currentGoodsId: '',
      loading: false,
      goodsList: [],
      storeLang: DefaultLanguage,
      moveIndex: -1,
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      orderLoading: false,
      list: []
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    classList() {
      const result = []
      this.model.classList.forEach(target => {
        const current = this.$store.state.goods.groupList.find(item => item.value === target.id)
        current && result.push(current)
      })
      return result
    },
    currentGoods() {
      return this.goodsList.find(item => item.id === this.currentGoodsId) || {}
    },
    awaitGoods() {
      return this.$store.state.site.awaitGoods
    }
  },
  async created() {
    this.storeLang = tools.getStoreLang(this)
    await this.$store.dispatch('goods/getGroupList', this)
    this.setDefaultClassify()
    await this.getGoodsList()
  },
  mounted() {
    Bus.$on('reloadGood', (type) => {
      const isLogin = type === 'login'
      if (isLogin) {
        this.checkBuy()
      }
    })
  },
  methods: {
    getTextColor,
    setDefaultClassify() {
      if (this.editing && this.model.classList.length === 0) {
        this.model.classList = this.$store.state.goods.groupList.map(item => {
          return {
            id: item.value
          }
        })
      }
    },
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      if (isAim) this.createOrder(this.awaitGoods.data)
    },
    closePay() {
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    handleChangeType(index) {
      if (this.current === index) return
      this.current = index
      this.currentGoodsId = ''
      this.goodsList = []
      this.getGoodsList()
    },
    async getGoodsList() {
      const params = {
        group_id: this.classList[this.current]?.value,
        pageSize: 100,
        pageNo: 1,
        project_id: this.$store.state.project.projectId
      }
      if (!params.group_id) return
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.good.getVirtualItems(params, { editing: this.editing }))
      this.loading = false
      if (!err) {
        this.goodsList = res.data.map(item => {
          const newItem = {...item}
          newItem.names = tools.getGoodInfo(item.names, {}, this.storeLang, this, this.$store.state.locale.langIdData)
          newItem.descriptions = tools.getGoodInfo({}, item.descriptions, this.storeLang, this, this.$store.state.locale.langIdData)
          newItem.priceInfo = tools.getPriceInfo(newItem.prices_setting, tools.getLangByip(this), this)
          return newItem
        })
      }
    },
    handleSelect(item) {
      this.currentGoodsId = item.id
    },
    onMouseIn(index) {
      this.moveIndex = index
    },
    ouMouseOut() {
      this.moveIndex = -1
    },
    async createOrder(goods) {
      this.orderLoading = true
      const [err, res] = await this.$utils.to(generateOrderParams(this, goods || this.currentGoods))
      this.orderLoading = false
      if (!err) {
        this.$store.commit('site/SET_AWAIT_GOODS', null)
        generatePayUrl(res, this)
      } else {
        !checkIgnoreMessage(err) && Message.error(err)
      }
    },
    async handleSubmit() {
      if (!this.currentGoodsId || this.orderLoading) return
      await checkUser(this, () => {
        this.$store.commit('site/SET_AWAIT_GOODS', { modelId: this.model.id, data: cloneDeep(this.currentGoods) })
      })
      await this.createOrder()
    }
  }
}
</script>

<style lang="less">
.store-list {
  &__content {
    max-width: 1200px;
    margin: 0 auto;
    .type-area {
      display: flex;
      align-items: center;
      overflow-x: auto;
      .item {
        width: 25%;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        .line {
          background-color: var(--button-background-color);
          height: 2px;
          width: 80px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
    .goods-area {
      display: flex;
      flex-wrap: wrap;
      .no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .item {
        width: 33.333333%;
        padding: 8px;
      }
    }
  }
  &.is-mobile {
    .goods-area {
      max-height: 480px;
      overflow-y: auto;
      .item {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
