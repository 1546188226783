<template>
  <div class="head-widget-edit-wrap">
    <menu-item class="menu-item-switch" pt="0px" :label="$t('head.backImg')">
      <menu-fill :target="model.customBackImg" target-key="customBackImg" eye-key="backgroundColorShow" />
      <menu-background-image
        class="mt-2"
        :model="model"
        :target="model.customBackImg"
        target-key="customBackImg"
        color-key="backgroundImage"
        eye-key="backgroundImageShow"
      />
    </menu-item>
<!--    <menu-item class="menu-item-switch" :label="$t('menu.backgroundBlur')">-->
<!--      <Slider ref="slider" v-model="model.backgroundBlur" target-key="backgroundBlur" />-->
<!--    </menu-item>-->
    <menu-divider></menu-divider>
    <menu-item class="menu-item-switch" pt="0px" :label="$t('head.logoImg')">
      <menu-background-image :target="model.logoBackImg" target-key="logoBackImg" event-key="head-logo-image" />
    </menu-item>
    <menu-item class="menu-item-switch" pt="0px" label="字体颜色">
      <menu-fill
        :target="model"
        color-key="headerFontColor"
        eye-key="headerFontColorShow"
      />
    </menu-item>
    <menu-item class="menu-item-switch" pt="0px" :label="$t('head.loginBtnBg')" size-tip="(建议比例4:3)">
      <menu-background-image :target="model.loginBtnBgImg" target-key="loginBtnBgImg" event-key="head-login-btn-bg-image"/>
    </menu-item>
    <menu-divider />
    <!-- <menu-item :label="$t('menu.menuLayout')">
      <im-select
        v-model="model.layout"
        :placeholder="$t('menu.menuLayout')"
        :options="layoutOptions"
        class="w-full"
      />
    </menu-item> -->
    <menu-item class="menu-item-switch" :label="$t('menu.headNav')">
      <nav-add :model="model" :site="site" :max-length="10" @edit="handleShowBackNavEditer" />
    </menu-item>
    <menu-item>
      <div class="check flex items-center">
        <checked-svg v-show="model.fixed" @click="setFixed"></checked-svg>
        <check-svg v-show="!model.fixed" @click="setFixed"></check-svg>
        <span class="text-white">{{ $t('menu.browserFixed') }}</span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('menu.sliderTip')"
          popper-class="help-popper"
          placement="top-start"
        >
          <tips></tips>
        </el-tooltip>
      </div>
    </menu-item>
  </div>
</template>

<script>
import { Tooltip } from 'element-ui'
import { HeaderWidgetModel } from '../../model/index'
import MenuItem from "~/components/menu/menuItem";
import NavAdd from "~/components/menu/navAdd";
import CheckedSvg from '~/assets/svg/icon/checked.svg'
import CheckSvg from '~/assets/svg/icon/check.svg'
import Tips from '~/assets/svg/icon/iconHelp.svg'
import menuFill from "~/components/menu/menuFill"
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import MenuDivider from "~/components/menu/divider"
import {insertStep} from "~/utils/event";
export default {
  name: "HeaderWidgetModel",
  components: {
    MenuItem,
    NavAdd,
    CheckedSvg,
    CheckSvg,
    ElTooltip: Tooltip,
    Tips,
    menuBackgroundImage,
    menuFill,
    MenuDivider
  },
  props: {
    model: {
      type: Object,
      default() {
        return new HeaderWidgetModel()
      }
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    extend: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isEdit: false,
      blurShadow: Number(this.model.backgroundBlur),
      layoutOptions: [
        {
          label: '左对齐',
          value: 'left'
        },
        {
          label: '居中对齐',
          value: 'center'
        },
        {
          label: '右对齐',
          value: 'right'
        }
      ]
    }
  },
  methods: {
    handleShowBackNavEditer(info) {
      this.SiteMenu.open({
        key: 'navediter',
        target: info
      })
    },
    setFixed() {
      insertStep(this, ['fixed'], this.model.fixed)
      this.model.fixed = !this.model.fixed
    }
  }
}
</script>

<style lang='less' scoped>
.head-widget-edit-wrap {
  .menu-item-switch {
    .custom-back-img {
      cursor: pointer;
      .upload-block {
        margin-bottom: 24px;
      }
    }
  }
  // .menu-item-store {
  //   //padding: 10px 24px 0;
  // }
  .check {
    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0 8px;
    }
  }
}
</style>
<style lang="less">
.head-widget-edit-wrap {
  .help-popper.is-dark {
    background-color: @primary-3;
    .popper__arrow {
      border-top-color: @primary-3 !important;
      border-bottom-color: @primary-3 !important;
      &::after {
        border-top-color: @primary-3 !important;
        border-bottom-color: @primary-3 !important;
      }
    }
  }
}
</style>
