// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".im-input-number{align-items:center;border:1px solid #ebeef1;border-radius:2px;display:flex}.im-input-number .number .el-input__inner{border:0;padding:0;text-align:center}.im-input-number.is-disabled{opacity:.3;pointer-events:none}.im-input-number.small{width:130px}.im-input-number.small .btn{height:36px;width:37px}.im-input-number.mini{width:86px}.im-input-number.mini .btn{height:26px;width:26px}.im-input-number.mini .number{font-size:12px}.im-input-number .btn{align-items:center;cursor:pointer;display:flex;font-weight:700;justify-content:center}.im-input-number .btn:first-of-type{border-right:1px solid #ebeef1}.im-input-number .btn:last-of-type{border-left:1px solid #ebeef1}.im-input-number .btn:hover{background-color:#ebeef1}.im-input-number .btn.disabled{color:#9b95bf;cursor:not-allowed}.im-input-number .btn.disabled:hover{background-color:transparent;background-color:initial}.im-input-number .number{flex:1;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
