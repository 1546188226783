<template>
  <div class="month-card-edit">
    <menu-item label="月/周卡">
      <im-select v-model="model.cardId" class="w-full" size="small" :options="$store.state.goods.cardList" />
    </menu-item>
    <menu-item label="背景设置">
      <menu-background-image :target="model.background" target-key="background" />
      <menu-fill class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" class="mt-2" label="描述" />
    </menu-item>
    <menu-item label="封面" size-tip="(建议比例3:4)">
      <menu-background-image :target="model.cover" target-key="cover" />
      <menu-fill class="mt-2" :target="model.cover" target-key="cover" />
    </menu-item>
    <menu-item label="移动端封面" size-tip="(建议比例3:2)">
      <menu-background-image :target="model.mobileCover" target-key="mobileCover" />
      <menu-fill class="mt-2" :target="model.mobileCover" target-key="mobileCover" />
    </menu-item>
    <menu-item label="封面背景">
      <menu-background-image :target="model.coverBackground" target-key="coverBackground" />
      <menu-fill class="mt-2" :target="model.coverBackground" target-key="coverBackground" />
    </menu-item>
    <menu-item label="卡片背景">
      <menu-background-image :target="model.cardBackground" target-key="cardBackground" />
      <menu-fill class="mt-2" :target="model.cardBackground" target-key="cardBackground" />
    </menu-item>
    <menu-item label="奖品背景">
      <menu-background-image :target="model.prizeBackground" target-key="prizeBackground" />
      <menu-fill class="mt-2" :target="model.prizeBackground" target-key="prizeBackground" />
    </menu-item>
    <menu-item label="奖品数量背景">
      <menu-background-image :target="model.prizeNumberBackground" target-key="prizeNumberBackground" />
      <menu-fill class="mt-2" :target="model.prizeNumberBackground" target-key="prizeNumberBackground" />
    </menu-item>
    <menu-item label="奖品数量">
      <menu-fill :target="model.prizeNumber" target-key="prizeNumber" />
    </menu-item>
    <menu-item label="购买/续费按钮背景">
      <menu-background-image :target="model.buttonBackground" target-key="buttonBackground" />
      <menu-fill class="mt-2" :target="model.buttonBackground" target-key="buttonBackground" />
    </menu-item>
    <menu-item label="购买/续费按钮文字颜色">
      <menu-fill class="mt-2" :target="model.buttonColor" target-key="buttonColor" />
    </menu-item>
    <menu-item label="领取按钮背景">
      <menu-background-image :target="model.getBackground" target-key="getBackground" />
      <menu-fill class="mt-2" :target="model.getBackground" target-key="getBackground" />
    </menu-item>
    <menu-item label="领取文字颜色">
      <menu-fill :target="model.getColor" target-key="getColor" />
    </menu-item>
    <menu-item label="已领取按钮背景">
      <menu-background-image :target="model.receiveBackground" target-key="receiveBackground" />
      <menu-fill class="mt-2" :target="model.receiveBackground" target-key="receiveBackground" />
    </menu-item>
    <menu-item label="已领取文字颜色">
      <menu-fill :target="model.receiveColor" target-key="getColor" />
    </menu-item>
    <menu-item label="文字颜色">
      <menu-fill :target="model.textColor" target-key="textColor" />
    </menu-item>
    <menu-item label="弹窗背景">
      <menu-fill :target="model.modalBackground" target-key="modalBackground" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import ImSelect from "~/components/common/ImSelect.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import RemoveBtn from "~/components/common/RemoveBtn.vue";

export default {
  name: 'MonthCardWidgetEdit',
  components: {RemoveBtn, MenuSwitch, MenuFill, MenuBackgroundImage, ImSelect, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      options: []
    }
  },
  created() {
    //
  },
  methods: {
  }
}
</script>

<style scoped lang="less">

</style>
