<template>
  <div class="quick-gradient">
    <div class="title text-14 text-white">选取渐变效果以开始编辑</div>
    <div class="quick-gradient__list">
      <div v-for="(item, index) in colorList" :key="index" class="item transition-all" :style="{ background: item.color }" @click="handleSelect(item)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickGradient",
  data() {
    return {
      colorList: [
        {
          color: 'linear-gradient(0deg, rgba(240, 69, 87, 1) 0%, rgba(221, 39, 57, 1) 100%)'
        },
        {
          color: 'linear-gradient(0deg, rgba(251, 51, 166, 1) 0%, rgba(127, 72, 176, 1) 50%, rgba(48, 145, 211, 1) 100%)'
        },
        {
          color: 'linear-gradient(0deg, rgba(255, 211, 226, 1) 0%, rgba(168, 255, 251, 1) 100%)'
        },
        {
          color: 'linear-gradient(54deg, rgba(114, 255, 179, 1) 14%, rgba(255, 250, 99, 1) 85%)'
        },
        {
          color: 'linear-gradient(180deg, rgba(255, 228, 49, 1) 0%, rgba(249, 32, 32, 1) 100%)'
        },
        {
          color: 'linear-gradient(0deg, rgba(255, 145, 129, 1) 0%, rgba(254, 91, 124, 1) 49%, rgba(255, 140, 162, 1) 100%)'
        },
        {
          color: 'linear-gradient(127deg, rgba(254, 217, 81, 1) 11%, rgba(255, 70, 199, 1) 81%)'
        },
        {
          color: 'linear-gradient(46deg, rgba(52, 133, 236, 1) 13%, rgba(170, 248, 219, 1) 86%)'
        }
      ]
    }
  },
  methods: {
    handleSelect(item) {
      this.$emit('select', item)
    }
  }
}
</script>

<style lang="less">
.quick-gradient {
  &__list {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 13px;
    .item {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      box-shadow: inset 0 0 0 .0625rem #0003;
      flex-shrink: 0;
      cursor: pointer;
      border: 1px solid white;
      &:hover {
        border-color: black;
      }
    }
  }
}
</style>
