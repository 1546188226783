<template>
  <div v-if="widget.visible" :id="widget.id" ref="widget" class="ui-widget-body relative">
    <div v-if="$store.getters.isMobile" class="widget-tag absolute top-0 left-0 px-2 py-1 text-white z-20 rounded-br" :class="selected && 'block'">
      {{ $t(`model.${menuInfo.title}`) }}
    </div>
    <div v-if="canDrag && (selected && !show) && $store.getters.isDesktop" class="tools-area flex items-center z-20">
      <div class="fun-item move-btn w-[32px] h-[32px] text-black rounded-full bg-textColor-6 hover:bg-white cursor-row-resize flex items-center justify-center">
        <i class="el-icon-rank text-18"></i>
      </div>
      <div class="fun-item ml-4 w-[32px] h-[32px] text-black rounded-full bg-textColor-6 hover:bg-white cursor-pointer flex items-center justify-center" @click="handleShowDeleteDialog">
        <i class="el-icon-delete text-18"></i>
      </div>
    </div>
    <add-block :model="model" :selected="selected" :can-drag="canDrag" :index="index" :widget="widget" @add="onWidgetAdd"/>
    <div class="widget-wrap" :class="{'active': selected}">
      <slot />
    </div>
  </div>
</template>

<script>
import addBlock from "~/components/addBlock"
export default {
  name: "UiWidgetBody",
  components: {
    addBlock
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    canDrag: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0,
    },
    widget:{
      type: Object,
      default() {
        return {
          getMenuInfo(){
            return {
              draggable: true
            }
          }
        };
      }
    },
    model: {
      type: Object,
      required: true,
      default() {
        return {
          getCanAddWidget() {
          }
        }
      }
    }
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    menuInfo() {
      return this.widget?.getMenuInfo() || {}
    }
  },
  watch: {
    selected() {
      this.show = false
    }
  },
  methods: {
    handleShowDeleteDialog() {
      this.SiteMenu.openDeleteBlockDialog()
    },
    onWidgetAdd(model, index) {
      this.$emit('add', model, index)
    },
    addWidgetByIndex(item) {
      this.model.addWidgetByIndex(item.model, this.index + 1)
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.ui-widget-body {
  position: relative;
  .widget-tag {
    background-color: rgba(245, 63, 63, 0.7);
    display: none;
  }
  &:hover {
    .widget-tag {
      display: block;
    }
  }
  .tools-area {
    position: absolute;
    top: 40px;
    left: 40px;
    cursor: pointer;
  }
  .widget-wrap {
    border: 1px solid transparent;
    &:hover {
      border: 1px dashed @error-color;
    }
    &.active {
      border: 1px dashed @error-color;
    }
  }
}
</style>
