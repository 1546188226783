// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recharge-activity{align-items:center;display:flex}.recharge-activity__logo{margin-right:40px}.recharge-activity__tip{background-color:#ceeaee;border-radius:2px;color:#292e42;padding:2px 11px;width:-moz-fit-content;width:fit-content}.recharge-activity__title .rich-text.focus-editor .ql-toolbar.ql-snow{flex-wrap:wrap}.recharge-activity__title .rich-text .ql-toolbar.ql-snow{width:300px}.recharge-activity__mobile-title .rich-text.focus-editor .ql-toolbar.ql-snow{flex-wrap:wrap}.recharge-activity__mobile-title.quill-wrap.top .ql-toolbar.ql-snow{left:110px}.recharge-activity.is-mobile{flex-wrap:wrap;padding:0 12px}.recharge-activity.is-mobile .recharge-activity__logo{margin-right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
