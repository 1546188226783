<template>
  <bg-style :bg="model.background" class="convert-key-widget px-5 py-8" :class="{ 'is-mobile': $store.getters.isMobile }">
    <div class="convert-key-widget__content relative">
      <rich-text v-if="model.titleVisible" v-model="model.title" :stroke.sync="model.titleStroke" :editing="editing" :class="$store.getters.isDesktop && 'xl:text-[40px]'" class="text-[20px] w-full title" theme="snow" :disabled="!editing" />
      <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :stroke.sync="model.subTitleStroke" :editing="editing" class="mt-3" :disabled="!editing" theme="snow" />
      <div class="mt-6 convert-key-widget__gift">
        <div v-for="(item, index) in giftList" :key="index" class="item">
          <img class="w-[60px] h-[60px]" :src="item.gift_goods_logo" alt="">
          <div class="number">{{ item.num }}</div>
        </div>
      </div>
      <im-form class="mt-8" label-position="top">
        <div class="w-[550px] m-0-auto" :class="{ '!w-full': $store.getters.isMobile }">
          <im-input v-model.trim="code" class="convert-input" :placeholder="$t('siteBuild.convertInput')" :disabled="editing" />
        </div>
        <form-item class="mt-[64px]">
          <div class="flex w-full items-center justify-center">
            <im-button class="submit-btn" :disabled="submitDisabled" :loading="loading" @click="handleSubmit">{{ $t('siteBuild.convert') }}</im-button>
          </div>
        </form-item>
      </im-form>
    </div>
    <confirm-dialog ref="confirm" :device="device" :detail="detail" />
  </bg-style>
</template>

<script>
import {mapState} from 'vuex'
import {Message} from "element-ui"
import isNil from "lodash.isnil"
import ConfirmDialog from './confirm.vue'
import RichText from "~/components/richText/index.vue"
import { Form, FormItem, Input, Button } from '~/site/components/forms'
import {DeviceEnum} from "~/enums/deviceEnum"
export default {
  name: 'ConvertKeyWidget',
  components: {
    ConfirmDialog,
    RichText,
    ImForm: Form,
    FormItem,
    ImInput: Input,
    ImButton: Button
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      code: '',
      giftList: [],
      detail: {
        redemption_code: '',
        gift: []
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
    submitDisabled() {
      return !this.code
    }
  },
  methods: {
    checkRight() {
      let result = true
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        result = false
      } else if (!this.code) {
        Message.error('Please Input Code')
        result = false
      }
      return result
    },
    async handleSubmit() {
      const valid = this.checkRight()
      if (!valid) return
      const params = {
        project_id: this.projectId,
        redemption_code: this.code
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.convertKey.detail(params))
      this.loading = false
      if (!err) {
        this.detail = res
        this.$refs.confirm.init()
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.convert-key-widget {
  .m-0-auto {
    margin: 0 auto;
  }
  &__content {
    max-width: 1240px;
    margin: 0 auto;
    .convert-input {
      .site-input__inner {
        font-family: PingFangSC-Medium;
        height: 61px;
        text-align: center;
        background-color: transparent;
        font-size: 40px;
        border-radius: 0;
        z-index: 10;
        position: relative;
        border-width: 2px;
        &:focus {
          border: 2px solid var(--button-background-color);
        }
        &:placeholder-shown {
          font-size: 24px;
        }
      }
    }
    .submit-btn {
      font-size: 20px;
      height: 46px;
      line-height: 46px;
      width: 142px;
    }
  }
  &__gift {
    display: flex;
    width: 100%;
    align-items: center;
    overflow: auto;
    gap: 10px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      //border-radius: 4px;
      flex-shrink: 0;
    }
  }
  &.is-mobile {
    .site-input__inner {
      &:placeholder-shown {
        font-size: 18px;
      }
    }
  }
}
</style>
