<template>
  <div class="color-model__gradient" :style="gradientStyle">
    <rotate ref="rotate" @spin="onSpin" @delete="onDelete" />
    <div class="relative">
      <div ref="thumb" class="thumb-area flex items-center" @mousedown.prevent="handleAddColorItem">
        <div
          v-for="(item, index) in colors"
          :key="index"
          :ref="`item${index}`"
          class="color-item absolute cursor-pointer"
          :class="{ 'active': currentIndex === index }"
          :style="{ transform: `translate3d(${item.left}px, 0, 0)` }"
          @mousedown.stop="dragStart($event, index)"
        >
          <div class="color-item__circle"></div>
        </div>
      </div>
      <div class="color-bar mt-2 relative">
        <div class="color-bar__inner absolute w-full h-full top-0 left-0"></div>
      </div>
      <div class="color-mark flex relative">
        <div v-for="(item, index) in colors" :key="index" class="mark-item flex items-center justify-center absolute" :style="{ transform: `translate3d(${item.left}px, 0, 0)` }">
          <div class="h-[3px] w-[3px] bg-textColor-3 rounded-full" />
        </div>
      </div>
      <div class="w-full flex items-center justify-between pb-4 pt-2 handle-action">
        <div class="text-textColor-3 text-12">色标</div>
        <div class="text-12 text-white">
          <i class="el-icon-plus font-bold" :class="{ 'is-disabled': plusDisabled }" @click="handleAddColorItemToNext" />
          <i class="el-icon-minus font-bold ml-2" :class="{ 'is-disabled': minusDisabled }" @click="deleteColorItem" />
        </div>
      </div>
      <color-picker v-if="colors[currentIndex]" v-model="colors[currentIndex].value" @input="onColorInput"  />
      <color-picker v-else v-model="placeColor" />
    </div>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'
import cloneDeep from "lodash.clonedeep";
import uniqueId from 'lodash.uniqueid'
import colorPicker from './picker'
import Rotate from './rotate.vue'
import {on, off} from "~/utils/dom";
import {formatValue, getGradientDeg} from '~/components/colorPickers/utils'
export default {
  components: {
    colorPicker,
    Rotate
  },
  props: {
    value: [String],
    parentRect: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      deg: 0,
      placeColor: '',
      colors: [],
      currentIndex: 0,
      maxColorLength: 10,
      itemRect: {
        width: 24
      },
      thumbRect: {},
      maxLeft: '',
      offsetLeft: '',
      downX: '',
      isDragging: false
    }
  },
  computed: {
    currentColorItem() {
      return this.colors[this.currentIndex] || {}
    },
    gradientStyle() {
      const colors = cloneDeep(this.colors)
      const deg = this.deg || 0
      let value = ''
      colors.sort((a, b) => a.left - b.left)
      colors && colors.forEach((item, index) => {
        const comma = index === colors.length - 1 ? '' : ','
        value += `${item.value} ${item.percent}% ${comma}`
      })
      return {
        '--osw-slider-background': `linear-gradient(90deg, ${value})`,
        '--osw-gradient-background': `linear-gradient(${deg}deg, ${value})`
      }
    },
    plusDisabled() {
      return this.colors.length >= this.maxColorLength
    },
    minusDisabled() {
      return this.currentColorItem.default || !(this.currentIndex > -1)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  destroyed() {
    off(document.body, 'keyup', this.deleteColorItemByKeyup)
  },
  methods: {
    onSpin(value) {
      this.deg = value
      this.emitValue()
    },
    setCurrentValue(value) {
      if (this.currentIndex > -1) {
        this.colors[this.currentIndex].value = value
        this.colors[this.currentIndex].origin = this.getOriginColor(value)
        this.emitValue()
      }
    },
    deleteColorItem() {
      const leftSortArr = cloneDeep(this.colors)
      leftSortArr.sort((a, b) => a.left - b.left)
      const isDefault = this.currentColorItem.default
      const currentSortIndex = leftSortArr.findIndex(item => this.currentColorItem.id === item.id)
      const prev = leftSortArr[currentSortIndex - 1]
      if (!isDefault && this.currentIndex > -1) {
        this.colors.splice(this.currentIndex, 1)
        this.currentIndex = this.colors.findIndex(item => item?.id === prev?.id)
      }
    },
    deleteColorItemByKeyup(event) {
      if (event.keyCode === 8 && this.currentIndex > -1) {
        this.deleteColorItem()
      }
    },
    init() {
      this.thumbRect = this.$refs.thumb.getBoundingClientRect()
      this.maxLeft = this.thumbRect.width - this.itemRect.width
      on(document.body, 'keyup', this.deleteColorItemByKeyup)
      this.getValue()
    },
    getOriginColor(value) {
      const color = tinycolor(value)
      return color.setAlpha(1).toRgbString()
    },
    getPercentWidth(percent) {
      const itemWidth = this.itemRect.width
      const value = Math.round((this.thumbRect.width - itemWidth) * (percent / 100))
      if (value <= 0) {
        return 0
      } else if (value >= this.thumbRect.width) {
        return value - itemWidth
      }
      return value
    },
    getValue() {
      const colors = formatValue(this.value)
      this.deg = getGradientDeg(this.value)
      this.$refs.rotate.setDeg(this.deg)
      if (colors && colors.length >= 2) {
        this.colors = colors.map(item => {
          const newItem = {...item}
          newItem.left = this.getPercentWidth(newItem.percent)
          newItem.origin = this.getOriginColor(newItem.value)
          newItem.id = uniqueId()
          return newItem
        })
        this.colors[0].default = true
        this.colors[this.colors.length - 1].default = true
      } else {
        this.setDefault()
      }
    },
    setDefault() {
      const { maxLeft } = this
      this.colors = [
        {
          id: uniqueId(),
          left: 0,
          percent: 0,
          origin: '',
          value: '',
          default: true
        },
        {
          id: uniqueId(),
          left: maxLeft,
          percent: 100,
          origin: '',
          value: '',
          default: true
        }
      ]
    },
    emitValue() {
      const that = this
      this.$emit('change', that.gradientStyle['--osw-gradient-background'])
    },
    emitPureValue() {
      this.$emit('pure-change', this.colors[this.currentIndex].value)
    },
    onColorInput(value) {
      this.colors[this.currentIndex].origin = this.getOriginColor(value)
      this.emitValue()
      this.emitPureValue()
    },
    getClickPercent(clientX) {
      return Number(((clientX - this.thumbRect.left) / this.thumbRect.width * 100).toFixed(2))
    },
    onDelete() {
      this.setDefault()
      this.$emit('cancel')
    },
    handleAddColorItem(event) {
      if (this.colors.length >= this.maxColorLength) return
      const itemWidth = 24
      const percent = this.getClickPercent(event.clientX)
      const maxLeft = this.thumbRect.width - itemWidth
      const downX = event.clientX - this.thumbRect.left
      const left = downX >= maxLeft ? maxLeft : (downX - itemWidth / 2)
      const color = tinycolor(this.currentColorItem.value).saturate(percent).toString()
      const item = {
        id: uniqueId(),
        value: color,
        origin: '',
        percent,
        left
      }
      this.colors.push(item)
      this.currentIndex = this.colors.length - 1
      this.emitValue()
    },
    handleAddColorItemToNext() {
      const rect = this.$refs[`item${this.currentIndex}`][0].getBoundingClientRect()
      const itemWidth = 36
      const left = rect.left + itemWidth
      this.handleAddColorItem({ clientX: left })

    },
    dragMove(event) {
      event.preventDefault()
      if (!this.isDragging) return
      const {clientX} = event
      const { currentIndex, maxLeft } = this
      const moveX = clientX - this.downX
      const left = moveX + this.offsetLeft - this.itemRect.width / 2
      let current = 0
      if (left <= 0) {
        current = 0
      } else if (left >= maxLeft) {
        current = this.maxLeft
      } else {
        current = left
      }

      this.colors[currentIndex].percent = Math.round((current / this.maxLeft) * 100)
      this.colors[currentIndex].left = current
      this.emitValue()
    },
    dragEnd() {
      off(document.body, 'mousemove', this.dragMove)
      off(document.body, 'mouseup', this.dragEnd)
    },
    dragStart(event, index) {
      const {clientX} = event
      this.currentIndex = index
      this.itemRect = this.$refs[`item${index}`][0].getBoundingClientRect()
      this.maxLeft = this.thumbRect.width - this.itemRect.width
      this.offsetLeft = this.itemRect.left - this.parentRect.left
      this.downX = clientX
      this.isDragging = true
      on(document.body, 'mousemove', this.dragMove)
      on(document.body, 'mouseup', this.dragEnd)
      this.emitPureValue()
    }
  }
}
</script>

<style lang="less">
.color-model__gradient {
  @itemBg: #2C2A37;
  @imgBg: "data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A";
  .thumb-area {
    height: 24px;
    position: absolute;
    border-radius: 24px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    cursor: crosshair;
    box-shadow: inset 0 0 0 .0625rem #00000012,inset 0 .0625rem .1875rem 0 #00000026;
    .color-item {
      width: 24px;
      height: 24px;
      padding: 3px;
      &__circle {
        width: 100%;
        height: 100%;
        border: 4px solid #fff;
        border-radius: 100%;
        position: relative;
      }
      &.active {
        .color-item__circle {
          &:after {
            content: "";
            position: absolute;
            top: -7px;
            left: -7px;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            border: 3px solid @primary-1;
          }
        }
      }
    }
  }
  .handle-action {
    .el-icon-minus,.el-icon-plus {
      cursor: pointer;
      &.is-disabled {
        color: @text-3;
        cursor: not-allowed;
      }
    }
  }
  .color-bar {
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    //background-color: white;
    //background-image: url(@imgBg);
    height: 24px;
    border-radius: 24px;
    &__inner {
      background: var(--osw-slider-background);
      border-radius: 24px;
      box-shadow: inset 0 0 0 .0625rem #00000012,inset 0 .0625rem .1875rem 0 #00000026;
    }
  }

  .color-mark {
    height: 20px;
    .mark-item {
      width: 24px;
      height: 20px;
    }
  }
}
</style>
